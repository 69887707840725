import React from 'react';
import { H5 } from '../../primitives/appTitle';

export const CguContentSpain = () => {
  return (
    <div>
      <div>
        <H5>1. Definiciones : </H5>
        <div css={{ marginBottom: 30 }}>
          Pedido: hace referencia al pedido para la realización de un reportaje
          fotográfico solicitado a través de la Plataforma SHOOOTIN. <br />{' '}
          <br /> Cuenta personal: significa la cuenta de la que dispone todo
          Usuario para acceder a la Plataforma, incluidos todos los datos
          personales relacionados al Usuario, concretamente sus Identificadores,
          que son necesarios para acceder a los servicios de la Plataforma
          SHOOOTIN. <br /> <br /> Identificadores: se refiere a la dirección de
          correo electrónico del Usuario, así como la contraseña elegida por el
          Usuario o la asignada por SHOOOTIN, lo que permite al Usuario
          identificarse para acceder a los servicios de la Plataforma. <br />{' '}
          <br /> Registro: designa el procedimiento de registro en línea que el
          Usuario debe completar en la plataforma SHOOOTIN para para poder
          acceder a dicha plataforma. Este registro conduce a la creación de los
          identificadores y la cuenta personal del usuario. <br /> <br />{' '}
          Fotógrafo: significa una persona que ejerce en calidad profesional la
          profesión de fotógrafo encargada por SHOOOTIN para llevar a cabo
          reportajes fotográficos. SHOOOTIN es quien elige a los fotógrafos.{' '}
          <br /> <br /> Plataforma: significa la plataforma SHOOOTIN accesible a
          través de la dirección https://shooot.in/, desde la cual los usuarios
          pueden acceder a todos los servicios proporcionado por SHOOOTIN.{' '}
          <br /> <br /> Usuario: significa cualquier persona registrada en la
          Plataforma y que solicita a través de este intermediario un reportaje
          fotográfico con SHOOOTIN.
        </div>
      </div>
      <div>
        <H5>2- Objeto de las CGUV y versión en vigor</H5>
        <div css={{ marginBottom: 30 }}>
          2.1. El propósito de estas CGUV es (i) definir las condiciones bajo
          las cuales los Usuarios pueden acceder y utilizar la Plataforma y (ii)
          gestionar las relaciones entre SHOOOTIN y los Usuarios. <br /> <br />{' '}
          2.2. Cualquiera que acceda a la Plataforma SHOOOTIN se compromete a
          respetar estas CGUV, las cuales son notificadas a los Usuarios para su
          aceptación antes de su Registro en la Plataforma en el momento de su
          primer pedido. <br /> <br /> 2.3. SHOOOTIN es libre de modificar, en
          cualquier momento y sin previo aviso, estas CGUV, en particular para
          tener en cuenta cualquier desarrollo legal, jurisprudencial, editorial
          y / o técnico. SHOOOTIN informará de ello al Usuario la próxima vez
          que se conecte a la Plataforma, quien deberá validar, mediante un
          checkbox, la nueva versión de las CGUV. <br />
          <br /> En cualquier caso, es para que el Usuario continúe utilizando
          la Plataforma después de la información de la modificación de las CGUV
          implica la aceptación de las modificaciones de las CGUV por parte del
          Usuario. La versión que prevalece es la que está disponible en línea.{' '}
          <br /> <br /> 2.4. Estas CGUV son aplicables a las relaciones entre
          SHOOOTIN y los Usuarios.
        </div>
      </div>
      <div>
        <H5>3. Descripción del funcionamiento de SHOOOTIN</H5>
        <div css={{ marginBottom: 30 }}>
          3.1. SHOOOTIN <br /> <br /> Actividad SHOOOTIN es una plataforma a
          través de la cual (i) Los usuarios solicitan reportajes fotográficos
          de bienes (en particular, sin que la lista sea exhaustiva,
          apartamentos, casas, oficinas, tiendas, restaurantes, etc.), (ii)
          estos Pedidos realizados por los Clientes son luego confiados por
          SHOOOTIN a un Fotógrafo, (iii) los derechos económicos de las
          fotografías tomadas por el Fotógrafo durante el reportaje fotográfico
          se transfieren al Cliente en el términos y condiciones aquí
          establecidos. <br /> <br /> 3.2. Pedido y aceptación de reportajes
          fotográficos <br />
          <br /> 3.2.1. Al solicitar un reportaje fotográfico por primera vez,
          el Usuario crea su Cuenta Personal, indicando los datos personales
          requeridos, los cuales son tratados de acuerdo con las disposiciones
          aquí previstas. <br /> <br /> Se especifica que el Usuario tiene la
          posibilidad de crear subcuentas para otros Usuarios, con roles
          definidos. En esta situación, los Usuarios accederán, con su propia
          dirección de correo electrónico y su propia contraseña, a la Cuenta
          Personal del Usuario principal, quien previamente les habrá autorizado
          a disponer de la totalidad o parte de los servicios de la Plataforma y
          / o acceder a todos o parte de la información de dicha Cuenta
          Personal. <br /> <br /> El Usuario realiza un Pedido a través de su
          Cuenta Personal. Para que esto sea válido y tomado en cuenta por
          SHOOOTIN, el Usuario deberá indicar el tipo de inmueble a fotografiar,
          la dirección de dicho inmueble, el número de habitaciones que contiene
          (solo para apartamentos y casas), y su superficie (en metros
          cuadrados), el tipo de “Pack SHOOOTIN” que elija, así como cualquier
          información adicional que el Usuario considere necesaria. Se
          especifica que la información solicitada por SHOOOTIN es probable que
          varíe en función de la evolución de la Plataforma. <br /> <br /> Por
          defecto, se indica que el Usuario concierta una cita con el Fotógrafo
          en la parte inferior de la edificio o más generalmente frente a la
          propiedad en cuestión. En caso contrario, el Usuario deberá desmarcar
          la casilla facilitada a tal efecto e indicar cualquier información
          necesaria para que el Fotógrafo acceda al inmueble a fotografiar, y en
          particular, en su caso, el código de acceso al inmueble. <br />
          <br /> Asimismo, se indica por defecto que el Usuario estará en el
          sitio durante la elaboración del reportaje fotográfico. De lo
          contrario, el Usuario debe desmarcar la casilla provista a tal efecto
          e indicar los datos de contacto del tercero que ha encomendado estar
          en el sitio (propietario o inquilino de la propiedad, custodio, etc.)
          para permitir que el Fotógrafo acceder a la propiedad para ser
          fotografiado así como para asistir al reportaje fotográfico. Estos
          datos de contacto incluyen el apellido, nombre y número de teléfono
          móvil del tercero. <br /> <br /> El Usuario se compromete a informar
          al tercero que los datos antes mencionados son recopilados y tratados
          por SHOOOTIN, y que los derechos que tiene sobre Estos datos, según lo
          previsto en el artículo 7 de estas CGUV, pueden ser ejercitadas
          directamente con SHOOOTIN, indicándoles la dirección de correo
          electrónico a la que se pueden enviar estas solicitudes. La
          transmisión de los datos personales del tercero a SHOOOTIN es
          imprescindible para la elaboración de reportajes fotográficos, cuando
          el Usuario no desea estar presente. <br /> <br /> El Usuario debe
          informar a SHOOOTIN, en el momento de la publicación de el Pedido, en
          la pestaña "Información adicional", si la propiedad a fotografiar fue
          realizada por un arquitecto (o diseñador de interiores), teniendo en
          cuenta las implicaciones en términos de propiedad intelectual, como se
          indica en el artículo 8 a continuación. <br /> <br /> En su defecto,
          SHOOOTIN considerará que la propiedad en cuestión no fue producida por
          un arquitecto (o diseñador de interiores) y que, en consecuencia,
          ninguna de las precauciones especiales por las que está mencionado en
          el artículo 8 siguiente no es aplicable. Cualquier consecuencia
          relacionada con esta información o la falta de ella es responsabilidad
          exclusiva del Usuario. Por lo tanto, la responsabilidad de SHOOOTIN no
          puede en ningún caso comprometerse a este respecto. <br /> <br /> El
          Usuario garantiza la veracidad y exactitud del Pedido que ha validado
          a través de la Plataforma. <br /> <br /> 3.2.2. Los términos y
          condiciones SHOOOTIN se encarga de encontrar un Fotógrafo para
          realizar el reportaje fotográfico. <br /> <br /> El Pedido no está
          sujeto a modificación por parte de los Fotógrafos. <br /> <br /> Por
          otro lado, el Usuario puede : <br /> <br /> - cancele el Pedido más de
          12 (doce) horas antes de la fecha de la cita. El Usuario será
          reembolsado, su Cuenta Personal será acreditada con el monto del
          Pedido. En el caso de un Pedido futuro, el Cliente puede utilizar los
          créditos acumulados para pagar el informe fotográfico solicitado.{' '}
          <br /> - cancele el Pedido con menos de 12 (doce) horas antes de la
          fecha de la cita. En este supuesto, Supuesto, SHOOOTIN no realizará
          ningún reembolso por la cancelación, que el Cliente reconoce y acepta
          expresamente. <br /> - modificar el Pedido, y en particular la fecha
          de la designación del reportaje fotográfico, más de 2 (dos ) horas
          antes de la fecha de la cita sin que el Cliente incurra en ningún
          coste adicional. <br /> <br /> El Usuario no puede modificar el
          Pedido, y en particular la fecha de la cita del reportaje fotográfico,
          menos de 2 (dos) horas antes de la hora programada inicialmente. En
          tal caso, el Pedido se cancelará automáticamente y SHOOOTIN no deberá
          reembolsar ni compensar al Usuario. <br /> <br /> Si la producción del
          informe ya había sido aceptada por un Fotógrafo, la producción del
          informe fotográfico puede ser asignado a otro Fotógrafo, si el
          Fotógrafo inicial deja de cumplir las condiciones de disponibilidad,
          ámbito geográfico o cualquier elemento especificado en el Pedido
          modificado por el Cliente. <br /> <br /> El Cliente garantiza la
          veracidad y precisión del Pedido según se publica en la Plataforma del
          Cliente o se modifica posteriormente. <br /> <br /> 3.2.3. La
          confirmación de la toma de responsabilidad de la realización del
          Pedido por parte de un Fotógrafo es confirmada por SHOOOTIN al
          Usuario, mediante el envío de un correo electrónico a la dirección
          indicada para la creación de su Cuenta Personal. <br /> <br /> En caso
          de aplazamiento del Pedido, el Usuario indica una nueva fecha en la
          que desea que se lleve a cabo el informe. SHOOOTIN es responsable de
          encontrar un Fotógrafo que pueda realizar el reportaje fotográfico.{' '}
          <br /> <br /> 3.2.4. El Usuario recibe una respuesta al Pedido que ha
          creado tan pronto como sea posible después de su publicación en la
          Plataforma, a excepción de fallas imprevistas sobre las cuales
          SHOOOTIN no tiene control. <br /> <br /> 3.2.5. Cuando se realiza un
          pedido con un fotógrafo, SHOOOTIN transmite los datos de contacto del
          fotógrafo al usuario y viceversa. El reportaje fotográfico se
          producirá de acuerdo con los términos del Pedido según lo especificado
          por el Usuario. <br /> <br /> 3.3. Elaboración de reportajes
          fotográficos <br /> <br /> El Fotógrafo realiza el reportaje
          fotográfico en la fecha y hora especificadas por el Usuario en el
          Pedido y según cualquier criterio específico que haya indicado durante
          la creación y / o modificación de el Pedido. <br /> <br /> El
          Fotógrafo toma al menos el número de tomas ordenadas por el Usuario
          durante el Pedido, excepto en el caso de una solicitud específica del
          Usuario. <br /> <br /> El informe fotográfico se refiere únicamente a
          los interiores y exteriores (fachadas) de la propiedad en cuestión. Un
          bien constituye un apartamento, casa, oficina o local comercial.
          Ninguna persona identificable (transeúnte, etc.) debe aparecer en las
          fotografías. Para ello se puede utilizar la técnica del desenfoque o
          cualquier otra técnica que permita hacer inidentificable a una
          persona. De lo contrario, la instantánea relevante tomada por el
          Fotógrafo no será entregada al Cliente. <br /> <br /> Si no es posible
          tomar fotografías por razones meteorológicas (lluvia, nieve, vientos
          fuertes), SHOOOTIN no puede ser responsable de no entregar
          determinadas fotografías y en particular fotografías externas. <br />{' '}
          <br /> A petición expresa del Usuario y con la autorización expresa
          por escrito de la persona fotografiada recogida por el Usuario, en las
          condiciones que se especifican a continuación en el artículo 8.5, que
          la fotografía puede representar a una persona física identificable en
          cuanto se vincule al lugar fotografiado (propietario, chef, etc.).
          SHOOOTIN no será responsable de la obtención de esta autorización, la
          cual deberá ser proporcionada por el Usuario a la primera solicitud.
          El Usuario garantiza SHOOOTIN en este punto. <br /> <br /> El
          Fotógrafo no es responsable de realizar ningún retoque de
          postproducción requerido después de la finalización de cada Pedido.
          SHOOOTIN realiza retoques de postproducción internamente, sujeto al
          respeto de los derechos morales del Fotógrafo y de cualquier
          arquitecto que produzca la propiedad en cuestión, así como al respeto
          de los derechos de terceros. <br /> <br /> 3.4. Validación de
          reportajes fotográficos <br />
          <br /> 3.4.1. SHOOOTIN valida internamente el reportaje fotográfico
          elaborado por el Fotógrafo, comprobando la adecuación de las fotos
          enviadas por el Fotógrafo con los criterios específicos del Pedido
          especificado por el Usuario así como con las especificaciones de
          SHOOOTIN. <br />
          <br /> 3.4.2. Si las fotos no cumplen con las especificaciones de
          SHOOOTIN y / o con los criterios especificados por el Usuario,
          SHOOOTIN informa al Fotógrafo por correo electrónico. SHOOOTIN podrá
          solicitar al Fotógrafo que realice nuevamente el reportaje
          fotográfico, lo cual el Fotógrafo reconoce y acepta expresamente.{' '}
          <br /> <br /> En este caso, el reportaje fotográfico se realizará en
          la fecha y hora indicada por SHOOOTIN, sujeto a disponibilidad del
          Usuario o del tercero que haya designado, comprometiéndose el
          Fotógrafo a ponerse a disposición. <br /> <br /> Si la producción de
          un nuevo reportaje fotográfico pospone la fecha de entrega de las
          imágenes al Cliente, SHOOOTIN informará al Cliente lo antes posible
          por correo electrónico. <br /> <br /> 3.5. Entrega del reportaje
          fotográfico <br /> <br /> 3.5.1. Dentro de las 48 horas hábiles
          siguientes a la finalización del reportaje fotográfico, SHOOOTIN
          entrega las fotos del reportaje al Usuario a través de la Plataforma,
          en la que el Usuario puede descargarlas en varios formatos. El Usuario
          es notificado de la disponibilidad de las fotos por correo
          electrónico. <br />
          <br /> En el caso de que las fotos del Fotógrafo no cumplan con las
          especificaciones de SHOOOTIN y / o los criterios del Usuario y que el
          Se tuvo que realizar la elaboración de un nuevo reportaje fotográfico,
          el plazo de entrega de las fotografías se pospone proporcionalmente.{' '}
          <br />
          <br /> 3.5.2. En caso de avería del sistema informático de la
          Plataforma durante el envío por parte del Fotógrafo o la descarga por
          SHOOOTIN o el Usuario de las fotos del reportaje fotográfico, el
          Usuario se compromete a informar a SHOOOTIN a la mayor brevedad, por
          correo electrónico . En este caso, y sin esperar la reparación de la
          falla técnica, las imágenes serán transferidas por cualquier medio
          alternativo, como por correo electrónico, sitio de transferencia, etc.
          En cualquier caso, SHOOOTIN no se hace responsable del retraso en la
          transmisión de imágenes debido a dicho fallo técnico. <br />
          <br /> 3.6. Seguimiento tras entrega del reportaje fotográfico <br />
          <br /> 3.6.1. El Usuario tiene acceso a las instantáneas de la
          Plataforma por un período de 7 (siete) días desde su publicación. De
          este modo, puede consultar las imágenes antes de descargarlas. Así, el
          Cliente deberá seleccionar las fotografías que se proponga conservar
          (salvo que el número de fotografías corresponda al número de
          fotografías incluidas en el "Pack SHOOOTIN" solicitado por el
          Usuario). La descarga de imágenes a través de la Plataforma constituye
          la validación del reportaje fotográfico por parte del Usuario. Si
          alguna vez el Usuario desea impugnar la totalidad o parte de las
          imágenes, en ningún caso debe seleccionarlas o descargarlas. El
          usuario no puede presentar ninguna disputa después de haber descargado
          todas o parte de las imágenes. En tal caso, la disputa no será válida.
          El Usuario no puede reclamar la responsabilidad de SHOOOTIN sobre esta
          base. <br />
          <br /> La queja del Usuario debe hacerse por correo electrónico a
          SHOOOTIN sobre criterios objetivos de calidad fotográfica y técnica,
          como el número de tomas, encuadres, niveles de luz, etc. <br />
          <br /> En caso de disputa del reportaje fotográfico por parte del
          Cliente (antes de cualquier descarga de las fotos), su queja se
          transmite inmediatamente a SHOOOTIN. En este caso, al Cliente le será
          imposible descargar las imágenes en disputa, que no podrá utilizar
          cualquiera que sea la finalidad, el medio y el medio previsto, que
          reconoce y acepta expresamente. <br />
          <br /> 3.6 .2. SHOOOTIN estudiará la reclamación del Usuario lo antes
          posible. <br />
          <br /> Son posibles varias opciones: (i) SHOOOTIN podrá rechazar la
          reclamación si no se basa en criterios técnicos y fotográficos
          objetivos. En este caso, el Usuario conservará el reportaje
          fotográfico tal y como le fue entregado, (ii) la denuncia se considera
          fundada por SHOOOTIN, en este caso el Usuario podrá solicitar que se
          realice nuevamente el reportaje fotográfico por parte del Fotógrafo,
          en las condiciones establecidas anteriormente, o que se le acredite a
          través de su Cuenta Personal el monto pagado por el Pedido para la
          elaboración de otro informe. El importe del Pedido no puede ser
          reembolsado al Usuario. <br />
          <br /> En caso de disputa, no se podrá descargar ni utilizar ninguna
          instantánea del reportaje fotográfico entregado por SHOOOTIN al
          Usuario y que éste haya impugnado por el Usuario, total o
          parcialmente, por cualquier medio, para cualquier finalidad y por
          cualquier medio. <br />
          <br /> 3.6.3. En el caso de un nuevo reportaje fotográfico, el proceso
          de validación es el mismo que el aquí establecido para cualquier
          reportaje fotográfico. <br />
          <br /> Si el Usuario no está satisfecho con el nuevo reportaje
          fotográfico, siempre que no lo esté 'no subió ninguna instantánea de
          este nuevo informe, y si esta disputa se basa en criterios objetivos,
          SHOOOTIN reembolsa al Usuario el monto del informe. <br />
          <br /> 3.7. Condiciones de pago de los reportajes fotográficos de los
          Usuarios <br />
          <br /> 3.7.1. El Usuario tiene varias opciones para pagar el costo
          (incluidos los impuestos aplicables previstos en Francia y el Reino
          Unido) del Pedido: <br />
          <br /> Mediante pago en efectivo, en el momento del Pedido que permite
          validar la fecha y hora de la cita indicada por el Usuario para la
          elaboración del reportaje fotográfico; <br /> A través de una Cuenta
          Personal prepaga: en este caso, la Cuenta Personal del Usuario se
          debita del monto del Pedido que ha realizado; <br /> Por facturación
          personalizada: el Usuario solicita la cantidad de reportajes
          fotográficos que desea, y se factura al final de cada mes. En este
          caso, las facturas deberán ser pagadas en un plazo máximo de 30
          (treinta) días desde su emisión por SHOOOTIN. ; <br /> Cuentas
          grandes: en este caso, es posible proporcionar condiciones de pago
          personalizadas, mutuamente acordadas entre el Cliente y SHOOOTIN por
          escrito, siendo estos escritos una parte integral de estas CGUV.{' '}
          <br /> El precio adeuda por el Usuario para la realización de un
          reportaje fotográfico varía según el "Pack SHOOOTIN" elegido por el
          Usuario, del cual se le informa cuando cumple con los criterios del
          Pedido. <br />
          <br /> L 'El Usuario puede adquirir más fotografías de las previstas
          inicialmente en el Pedido (salvo que el número de fotografías
          entregadas por el Fotógrafo corresponda al número de fotografías
          incluidas en el "Pack SHOOOTIN"), en el momento en que selecciona y
          descarga las fotografías en la plataforma. En este caso, SHOOOTIN le
          factura un costo adicional por fotografía, pagadero en las mismas
          condiciones que las anteriores. <br />
          <br /> Pagos relacionados con Pedidos de informes y en caso de
          provisión de Cuentas Personales de los Usuarios, se realizan a través
          del proveedor de pago STRIPE (solución de pago en línea utilizable
          para el pago de Pedidos). <br />
          <br /> Los métodos de pago son específicos de esta plataforma y son
          independientes de SHOOOTIN. SHOOOTIN no conserva ningún dato bancario
          del Usuario. SHOOOTIN no se hace responsable de ningún mal
          funcionamiento que se produzca durante el pago. Su responsabilidad no
          puede comprometerse a este respecto. <br />
          <br /> Sin embargo, al ser utilizada la herramienta STRIPE en el
          contexto del uso de los servicios de la Plataforma SHOOOTIN, se
          entiende que SHOOOTIN debe ser considerado como el responsable del
          tratamiento de los datos personales recogidos en el contexto de las
          operaciones de pago realizado a continuación. Dichos datos personales
          se procesan de acuerdo con el artículo 7 siguiente. <br />
          <br /> Al aceptar estos T &amp; C, los Usuarios también aceptan estar
          sujetos a las Condiciones Generales de Uso de Stripe. Estos están
          sujetos a cambios por parte de Stripe. Se precisa que en ningún caso
          SHOOOTIN podrá modificar las Condiciones Generales de Uso de STRIPE,
          que es exclusivamente un prestador de servicios, sobre el cual no
          tiene control. <br />
          <br /> 3.7.2. Si el Fotógrafo se retira del Pedido menos de 24 horas
          antes de la fecha y hora programadas y es probable que ningún otro
          Fotógrafo complete el Pedido en los términos y condiciones indicados
          por el Usuario, el monto del Pedido se acredita a la Cuenta Personal
          del Usuario, que puede utilizar para otro Pedido. <br />
          <br /> 3.7.3. Cualquier demora en el pago en la fecha de vencimiento
          indicada anteriormente, después de que la notificación formal no haya
          tenido éxito durante un período de 7 (siete) días desde la recepción,
          incurrirá en intereses a la tasa legal. <br />
          <br /> Si l ' El Usuario ha optado por no realizar el pago en el
          momento del Pedido, se entiende que en caso de retraso en el pago por
          su parte en la fecha de vencimiento, y en su caso, una penalización
          calculada en base a la tarifa aplicada por el Banco Central Europeo,
          incrementada en quince (15) puntos, será a cargo de SHOOOTIN, además
          de una indemnización a tanto alzado de 40 €, correspondiente a los
          gastos de recuperación. <br />
          <br /> 3.8. Términos relacionados con el funcionamiento general de la
          Plataforma <br />
          <br /> 3.8.1. SHOOOTIN es un proveedor de servicios, cuya función es
          gobernar las relaciones entre Fotógrafos y Usuarios, incluyendo en
          particular la elección del Fotógrafo, el seguimiento de la realización
          de los reportajes fotográficos, la realización de los retoques de
          postproducción, así como la entrega. informa a los usuarios. Los
          Usuarios representan sus propios intereses: entregan o aceptan que se
          les faciliten reportajes fotográficos bajo su única y entera
          responsabilidad. <br />
          <br /> 3.8.2. Los fotógrafos son profesionales, lo que significa que
          tienen un diploma o título relacionado con el ejercicio de la
          profesión de fotógrafo, o al menos tienen una experiencia profesional
          significativa en el campo de la fotografía. <br />
          <br /> Como tal, SHOOOTIN garantiza al Usuario haber seleccionado al
          Fotógrafo sobre la base de criterios objetivos. Por otra parte,
          SHOOOTIN no puede garantizar al Usuario que el reportaje fotográfico
          encomendado al Fotógrafo sea perfectamente conforme con las
          especificaciones de SHOOOTIN y / o los criterios que especificó al
          realizar el pedido del Pedido. Se entiende que en este caso se
          aplicarán las disposiciones de estas CGU relativas a esta situación.
        </div>
      </div>
      <div>
        <H5>4. Limitación de responsabilidad</H5>
        <div css={{ marginBottom: 30 }}>
          4.1. El Usuario reconoce estar informado y acepta que SHOOOTIN no
          puede ser, en modo alguno, responsable de la creación, modificación,
          supresión o falta de recepción, transmisión, transmisión o
          almacenamiento. Datos del usuario, bajo la única y entera
          responsabilidad de este último. Los pedidos de los usuarios se
          publican en la plataforma bajo su exclusiva responsabilidad. Los
          Usuarios garantizan a SHOOOTIN frente a cualquier reclamación y
          recurso de cualquier persona física o jurídica que se considere
          agraviada por los contenidos publicados. <br /> <br /> Asimismo,
          SHOOOTIN no se hace responsable del retraso o la falla del servidor o
          de la red de Internet en relación con el envío de reportajes
          fotográficos a los Usuarios y / o su descarga desde la Plataforma.{' '}
          <br /> <br /> 4.2. El funcionamiento de los servicios ofrecidos por
          SHOOOTIN depende principalmente de factores que no están bajo el
          control de SHOOOTIN, en particular en lo que respecta a las
          habilidades y cualidades relacionales de los Fotógrafos, que SHOOOTIN
          no puede garantizar, y de las cuales este último de ninguna manera
          puede ser responsable. <br /> <br /> SHOOOTIN no proporciona ningún
          otro servicio y no garantiza, ya sea directa o indirectamente, la
          veracidad y precisión de los pedidos (incluida la información y las
          características relacionadas con los bienes) realizados línea de los
          Usuarios en la Plataforma. <br /> <br /> SHOOOTIN por lo tanto declina
          cualquier responsabilidad por el contenido de los Pedidos publicados
          por los Usuarios y por el comportamiento de los Usuarios. Dado que el
          contenido de los Pedidos publicados en la Plataforma es generado por
          los propios Usuarios, SHOOOTIN no se hace responsable de la veracidad
          de los anuncios o de la legitimidad de los pedidos de reportajes
          fotográficos, ni de los términos y contenido de los Pedidos. Cualquier
          Pedido que le parezca probable que no cumpla con la ley y las
          regulaciones aplicables debe ser informado enviando un correo
          electrónico a la dirección de contacto: contact@shooot.in. <br />
          <br />, Por lo tanto, la responsabilidad de SHOOOTIN no puede ser
          comprometidos, directa o indirectamente, en esta capacidad. <br />{' '}
          <br /> 4.3. SHOOOTIN no se hace responsable de ningún defecto, vicio
          oculto, falta de conformidad de los equipos utilizados por el
          Fotógrafo para realizar el reportaje fotográfico, o en caso de abuso,
          deslealtad o fraude emanado de un Usuario o tercero, o cualquier otra
          falta penal o delito cometido por otro Usuario o un tercero durante el
          uso de la Plataforma. <br /> <br /> 4.4. SHOOOTIN no proporciona
          ninguna garantía expresa o implícita, incluyendo, sin que esta
          enumeración sea exhaustiva, relacionada con la continuidad y
          sostenibilidad de la Plataforma, el desempeño de la Plataforma, el
          cumplimiento o la compatibilidad de la Plataforma. para un uso
          específico, por la calidad o ausencia de vicio o defecto de la
          Plataforma, por el desalojo y no violación de las leyes y reglamentos
          o estas CGU de la Plataforma por parte de otros Usuarios. <br />{' '}
          <br /> 4.5. Ningún consejo o información, ya sea oral o escrita,
          obtenido por el Usuario de SHOOOTIN o durante el uso de la Plataforma,
          es responsable de crear garantías que no estén expresamente previstas
          por estas CGUV. <br /> <br /> 4.6. El Usuario reconoce que su uso de
          la Plataforma es bajo su propio riesgo. La Plataforma se le
          proporciona "tal cual" y es accesible sin ninguna garantía de
          disponibilidad y regularidad. SHOOOTIN hará sus mejores esfuerzos para
          que la Plataforma sea accesible las 24 horas del día, los siete días
          de la semana, excepto en caso de fuerza mayor o evento fuera del
          control de SHOOOTIN y sujeto a períodos de mantenimiento, posibles
          averías, contingencias. técnicas relacionadas con la naturaleza de la
          red o actos maliciosos o cualquier ataque al hardware o software de
          SHOOOTIN. <br />
          <br /> 4.7. SHOOOTIN no se hace responsable de ninguna manera por una
          interrupción de la totalidad o parte de la Plataforma,
          independientemente de la causa, duración o frecuencia de dicha
          interrupción. <br /> <br /> 4.8. SHOOOTIN no se hace responsable de
          ninguna manera en caso de pérdida, robo, rotura o cualquier otro
          evento que pueda afectar la condición y / o posesión de la propiedad
          del Cliente durante la producción del reportaje fotográfico por parte
          del Fotógrafo. En este caso, es la responsabilidad civil profesional
          del Fotógrafo la que debe ser implementada, de la que el Usuario se
          hace cargo. <br /> <br /> Asimismo, SHOOOTIN no se hace responsable de
          los actos y hechos de terceros designados por los Usuarios que
          intervengan durante los reportajes fotográficos, de los que los
          Usuarios hacen su negocio. SHOOOTIN no se hace responsable de las
          condiciones en las que se producen reportajes fotográficos difíciles.{' '}
          <br /> <br /> 4.9. SHOOOTIN no interviene de ninguna manera durante la
          elaboración del reportaje fotográfico en sí. Por tanto, no se le puede
          imputar responsabilidad alguna sobre esta base, ya sea en particular
          en lo que respecta a las condiciones de elaboración del informe o los
          viajes necesarios para realizarlo. Esto es perfectamente independiente
          de la voluntad de SHOOOTIN, que el Usuario y el Fotógrafo reconocen y
          aceptan expresamente. <br /> <br /> También se especifica que cuando
          el Usuario no deseaba estar presente durante el reportaje fotográfico,
          El tercero designado por el Usuario para atender y permitir el acceso
          del Fotógrafo al inmueble no tiene ningún vínculo directo o indirecto,
          de ningún tipo, con SHOOOTIN. La única persona con la que este tercero
          está en contacto y / o en una relación comercial es el Usuario. <br />{' '}
          <br /> Así, en el caso de que ocurra un evento durante la elaboración
          del reportaje fotográfico y que concierna o involucre al tercero en
          cuestión, este evento no puede comprometer la responsabilidad de
          SHOOOTIN por ningún motivo sobre esta base. <br /> <br /> Se recuerda
          al respecto que SHOOOTIN no puede ser responsable de cualquier
          dificultad en la relación entre Usuarios externos en el marco previsto
          por estas CGUV, ya sea con los Fotógrafos o con terceros, ya sean en
          particular los arrendatarios o propietarios de los bienes en cuestión,
          o terceros designados por los Usuarios para ayudar en el reportaje
          fotográfico. <br />
          <br /> 4.10. Corresponde al Usuario respetar las obligaciones legales
          relacionadas con la realización de un reportaje fotográfico, por
          ejemplo, en su caso y sin que esta lista sea exhaustiva, las
          declaraciones administrativas, fiscales y sociales obligatorias que
          resulten de la ejecución del reporte. photographyique. <br /> <br />{' '}
          Por tanto, corresponde a los Usuarios respetar las obligaciones
          legales derivadas del cobro de una suma de dinero, en particular la
          posible declaración de los ingresos así percibidos, así como, en su
          caso, las obligaciones impuesto, en particular la recaudación del IVA.{' '}
          <br /> <br /> 4.11. Se acuerda que en caso de que se cuestione la
          responsabilidad de SHOOOTIN, independientemente del fundamento y / o
          naturaleza de la acción, que solo el daño directo puede dar lugar a
          indemnización, que todo daño indirecto , consecutivos y / o
          incidentales, como por ejemplo un disturbio comercial, pérdida de
          clientela, etc., no darán lugar al derecho a compensación en beneficio
          del Fotógrafo y / o del Usuario. <br /> <br /> En todos En este caso,
          la responsabilidad de SHOOOTIN se limita, independientemente de la
          base y / o la naturaleza de la acción emprendida en su contra, al
          monto neto recibido por SHOOOTIN en el marco del presente.
        </div>
      </div>
      <div>
        <H5>5. Uso de la plataforma - Obligaciones de los usuarios</H5>
        <div css={{ marginBottom: 30 }}>front_cgu_description_4</div>
      </div>
      <div>
        <H5>6. Seguros</H5>
        <div css={{ marginBottom: 30 }}>
          6.1. El usuario garantiza a SHOOOTIN que tiene los seguros necesarios
          (daños a la propiedad y daños corporales) para cubrir los
          acontecimientos que puedan ocurrir durante la realización del
          reportaje fotográfico dentro y / o fuera de la propiedad en cuestión.{' '}
          <br /> <br /> 6.2. Los usuarios también declaran que están cubiertos
          por un seguro de responsabilidad civil, que cubre todos los daños
          corporales (agresiones o accidentes en particular) o materiales que
          pueda ocurrir en el contexto de la realización de reportajes
          fotográficos que SHOOOTIN les confía, incluidos también los trayectos
          de ida y vuelta efectuados, así como la ejecución de los propios
          reportajes. <br /> <br /> 6.3. Los usuarios deben proporcionar a
          SHOOOTIN cualquier certificado de seguro mencionado en este artículo,
          previa solicitud.
        </div>
      </div>
      <div>
        <H5>7. Datos personales</H5>
        <div css={{ marginBottom: 30 }}>
          7.1. Los usuarios deben proporcionar ciertos datos personales para
          acceder a la Plataforma y así crear una Cuenta Personal, a la que
          acceden mediante un nombre de usuario y / o contraseña. <br /> <br />{' '}
          Esto también se aplica cuando los usuarios crean subcuentas de una
          cuenta personal. En este caso, las personas cuyas subcuentas se crean
          deben ser informadas de esta creación así como del procesamiento de
          sus datos personales por parte del Usuario de la Cuenta Personal. Los
          datos de estos Usuarios de subcuentas que están siendo procesados
          ​​por SHOOOTIN, se aplica este artículo. <br /> <br /> Los datos
          personales de los Usuarios están sujetos a procesamiento automatizado
          por SHOOOTIN para fines de administración y administración de su
          cuenta, así como a efectos de puntuar la ejecución de Órdenes y
          estudios estadísticos. <br /> <br /> Los Usuarios reconocen y aceptan
          expresamente que sus datos personales serán transmitidos por SHOOOTIN
          a otros Usuarios, así como, en su caso, a terceros designados por el
          Usuario que no deseen colaborar en la elaboración del reportaje
          fotográfico, y esto con la única finalidad de realizar los reportajes
          fotográficos en las condiciones y modalidades previstas en estos CGUV.{' '}
          <br /> <br /> Por otro lado, en ningún caso los datos personales de
          los Usuarios no son transmitidos a socios de SHOOOTIN. En tal
          eventualidad, se solicitará obligatoriamente el consentimiento de los
          Usuarios mediante una casilla de verificación, para autorizar
          expresamente dicha transmisión. <br />
          <br /> En aplicación de la ley n ° 78-17 del 6 de enero de 1978
          modificado en relación con el tratamiento de datos, archivos y
          libertades, cada Usuario tiene derecho a acceder, modificar,
          rectificar y suprimir los datos que le conciernen. Por tanto, podrá
          exigir que toda información que le concierna que sea inexacta,
          incompleta, equívoca o desactualizada sea rectificada, completada,
          aclarada, actualizada o eliminada. El Usuario también puede, por
          motivos legítimos, oponerse al tratamiento de los datos que le
          conciernen. <br /> <br /> Cada Usuario puede ejercer este derecho en
          línea en cualquier momento accediendo a su Cuenta Personal. En caso de
          dificultad, el Usuario puede ejercer estos derechos enviando un correo
          electrónico a la siguiente dirección: contact@shooot.in. 7.2. Cuando
          se cierra la cuenta personal de un usuario, por cualquier motivo, los
          datos relacionados con esta cuenta y, en particular, los datos de
          tráfico se borran o se anonimizan y solo pueden utilizarse con fines
          estadísticos. No obstante, podrá diferirse por un período máximo de 1
          (un) año a operaciones tendientes a borrar o anonimizar determinadas
          categorías de datos con el fin de garantizar la seguridad de las
          instalaciones de SHOOOTIN y con fines de investigación, la detección y
          el enjuiciamiento de delitos penales, y con el único objetivo de
          permitir, según sea necesario, el suministro de información a la
          autoridad judicial. <br /> <br /> 7.3 Es probable que SHOOOTIN ponga
          en coloca cookies en la Plataforma. Se trata de un proceso de
          seguimiento automático que registra información relacionada con la
          navegación en la Plataforma y almacena la información ingresada
          durante las visitas con el fin de facilitar el procedimiento de
          registro y uso de la Plataforma por parte de los Usuarios. El Usuario
          puede oponerse a su instalación y / o eliminarlos siguiendo el
          procedimiento indicado en su navegador. <br /> <br /> Por tanto,
          SHOOOTIN recaba el consentimiento expreso de los Usuarios para el uso
          de estos cookies, que se utilizan de acuerdo con la normativa antes
          mencionada, conocida como la ley “Informatique et Libertés”. <br />{' '}
          <br /> SHOOOTIN utiliza estas cookies solo para establecer
          estadísticas de conexión así como el historial de navegación de los
          Usuarios. <br />
          <br /> 7.4. De acuerdo con las disposiciones de estas CGUV, el Usuario
          puede encargar a un tercero, como por ejemplo el inquilino de la
          propiedad o el conserje, que ayude en la producción del reportaje
          fotográfico de la propiedad en cuestión, si no desea asistir él mismo.{' '}
          <br /> <br /> En este caso, el Usuario se compromete a solicitar y
          obtener la autorización expresa de este tercero para que sus datos
          personales, a saber, nombre, nombre y teléfono, sean recogidos y
          transmitidos. por SHOOOTIN de acuerdo con las disposiciones legales
          vigentes, en las condiciones y modalidades previstas en estas CGUV, y
          únicamente para la ejecución de estas CGUV. <br />
          <br /> Los datos de dicho tercero serán indicados por el Usuario y
          recogidos por SHOOOTIN cuando 'El Usuario realiza un Pedido en línea.{' '}
          <br /> <br /> El Usuario se compromete a proporcionar cualquier
          documento que presente el acuerdo del tercero para la recopilación de
          sus datos personales por SHOOOTIN, en el cuadro de la ejecución de
          estas CGUV. <br /> <br /> Tan pronto como el reportaje fotográfico,
          para el cual se recogen los datos del tercero encomendado por el
          Usuario, sea realizado por el Fotógrafo y validado por el Usuario,
          SHOOOTIN s ' se compromete a suprimir a la mayor brevedad cualquier
          dato personal relativo al tercero facilitado por el Usuario y recogido
          por SHOOOTIN.
        </div>
      </div>
      <div>
        <H5>8. Propiedad intelectual - Derechos de imagen</H5>
        <div css={{ marginBottom: 30 }}>
          8.1. El Fotógrafo que toma las fotos del reportaje fotográfico cede en
          exclusiva a SHOOOTIN, quien acepta, sus derechos económicos sobre
          todas las fotos tomadas como parte de cada reportaje fotográfico, en
          el momento de su realización. <br /> <br /> Los derechos patrimoniales
          transferidos incluyen en particular los derechos de reproducción,
          representación, adaptación, traducción, para transferir a un tercero
          la totalidad o parte de los derechos transferidos, y esto por todos
          los métodos y procedimientos conocidos o desconocidos hasta la fecha.{' '}
          <br /> <br /> Estos derechos se transfieren durante todo el período de
          protección de los derechos de autor de acuerdo con las leyes francesas
          y extranjeras, así como las convenciones internacionales actuales y
          futuras, incluida cualquier posible extensión futura, y para todo el
          mundo. <br /> <br /> 8.2. Después de haber adquirido todos los
          derechos patrimoniales del Fotógrafo sobre todas las fotografías
          tomadas como parte del reportaje fotográfico ordenado por el Usuario y
          realizado por el Fotógrafo en virtud de estas CGUV, SHOOOTIN cede
          exclusivamente al Usuario, quien acepta todos los derechos
          patrimoniales sobre dichas fotografías, a cambio del precio pagado por
          el Usuario a SHOOOTIN, que incluye el precio del servicio de reportaje
          fotográfico realizado por el Fotógrafo y la cesión de los derechos
          relacionados por SHOOOTIN. <br /> <br /> Los derechos patrimoniales
          cedidos incluyen en particular los derechos de reproducción,
          representación, adaptación, ceder a un tercero la totalidad o parte de
          los derechos cedidos, y esto por todos los métodos y procedimientos
          conocidos o desconocidos hasta la fecha, para la totalidad plazo de
          protección de los derechos de autor de acuerdo con las leyes francesas
          y extranjeras, así como las convenciones internacionales actuales y
          futuras, incluidas las extensiones futuras posible, y para todo el
          mundo. <br />
          <br /> SHOOOTIN se abstiene expresamente de autorizar la reproducción,
          representación y explotación de las obras a las que se refieren estas
          CGUV por otras personas físicas o jurídicas, con fines comerciales o
          no, mientras dure la cesión de los derechos previstos anteriormente,
          salvo consentimiento previo expreso del Usuario. <br /> <br /> El
          Usuario se compromete a respetar los derechos morales de los
          Fotógrafos que ha realizado las fotografías (mención del nombre del
          Fotógrafo, sin modificación o alteración de las fotografías tomadas).{' '}
          <br /> <br /> En caso de incumplimiento de esta obligación por parte
          del Usuario, la responsabilidad de SHOOOTIN no puede ser ningún caso
          se contratará. <br /> <br /> Se entiende que la cesión aquí prevista
          no puede asimilarse a una cesión global de obras futuras, que está
          prohibida por el artículo L. 131-1 del Código de la Propiedad
          Intelectual . <br /> <br /> Sin perjuicio de la presente cesión de
          derechos sobre las fotografías por SHOOOTIN al Usuario autor, SHOOOTIN
          se reserva expresamente (i) el derecho de utilizar las obras
          transferidas con fines de marketing, publicidad y promoción, en
          cualquier medio, (ii) la posibilidad de mantener las obras
          transferidas en una base de datos, en particular para
          proporcionárselas nuevamente al Usuario en caso de pérdida de las
          fotografías transferidas, que el Usuario reconoce y acepta
          expresamente. <br /> <br /> En cualquier caso, el Fotógrafo seguirá
          siendo libre de utilizar las fotografías en las condiciones definido
          en el artículo 8.1 de las CGUV. <br /> <br /> 8.3. Sin que esta lista
          sea exhaustiva, la marca "SHOOOTIN" así como sus derivados, los
          logotipos, la carta gráfica, la maquetación, la información, la
          presentación y el contenido de la Plataforma, son propiedad exclusiva
          de SHOOOTIN. <br /> <br /> Los sistemas, software, estructuras,
          infraestructuras, bases de datos y contenido de cualquier tipo
          (textos, imágenes, visuales, música, logotipos, marcas, base de datos,
          etc.) operados por SHOOOTIN en la Plataforma también están protegidos.
          por todos los derechos de propiedad intelectual o derechos de los
          productores de bases de datos vigentes. Queda estrictamente prohibido
          todo desmontaje, descompilación, descifrado, extracciones,
          reutilización, copias y en general, todos los actos de reproducción,
          representación, distribución y uso de cualquiera de estos elementos,
          total o parcialmente, sin la autorización de SHOOOTIN. puede ser
          objeto de procedimientos legales. <br /> <br /> Cualquier reproducción
          o representación, total o parcial, de la Plataforma o de los elementos
          que la componen, tales como marcas, logotipos, carta gráfica,
          maquetación, información , la presentación y el contenido de la
          Plataforma, sin que esta lista sea exhaustiva, están prohibidos.{' '}
          <br /> <br /> El registro en la Plataforma no implica ninguna
          transferencia de derechos de propiedad intelectual en beneficio del
          Usuario. <br /> <br /> br} <br /> 8.4. El Usuario declara a SHOOOTIN y
          garantiza expresamente a SHOOOTIN haber obtenido todas las
          autorizaciones necesarias, en particular del propietario y / o
          arrendatario de la (s) propiedad (s) en cuestión, pero también, en su
          caso, del arquitecto. quién creó los planos, o ha realizado el bien
          fotografiado, para que el Fotógrafo pueda realizar el reportaje
          fotográfico en las condiciones aquí expuestas. El Usuario
          proporcionará estas autorizaciones a la primera solicitud de SHOOOTIN.{' '}
          <br /> <br /> Además, el Usuario garantiza a SHOOOTIN que las imágenes
          que constituyen el reportaje fotográfico no causarán vulneración de
          los derechos morales del arquitecto. <br /> <br /> Por lo tanto, la
          responsabilidad de SHOOOTIN no puede en ningún caso comprometerse a
          este respecto. <br /> <br /> 8.5. Durante la producción del informe
          fotográfico, el inquilino, el propietario, cualquier tercero encargado
          de asistir a dicho informe y, en general, cualquier persona física, no
          aparecen en las fotografías. En su defecto, la instantánea en cuestión
          no podrá ser entregada al Cliente. <br /> <br /> Sin embargo, a
          solicitud del Usuario, y con la autorización expresa por escrito del
          interesado, las instantáneas tomadas en el marco del presente pueden
          representar un persona física (ver artículo 3.4). <br /> <br /> El
          Usuario se compromete a obtener del interesado la autorización para
          reproducir su imagen de acuerdo con los términos y condiciones aquí
          previstos. Si es necesario, también se compromete a obtener la
          autorización de la persona, en particular en lo que respecta a la
          mención de su nombre, nombre, cargo y profesión. <br /> <br /> El
          Usuario reconoce ser el único responsable de la recopilación de estos
          autorizaciones, la responsabilidad de SHOOOTIN no puede en ningún caso
          comprometerse al respecto. El Cliente se compromete a comunicar, a
          primera solicitud, cualquier documento que permita acreditar la
          veracidad de estas autorizaciones a SHOOOTIN. <br /> <br /> 8.6. Sin
          perjuicio de la terminación y / o en general la finalización de las
          CGUV por cualquier motivo, las cesiones de derechos previstas en este
          documento siguen siendo válidas en los términos y condiciones
          establecidos.
        </div>
      </div>
      <div>
        <H5>
          9. Disposiciones del Código de consumo aplicables al Usuario no
          profesional
        </H5>
        <div css={{ marginBottom: 30 }}>
          De acuerdo con las disposiciones del Código del Consumidor, los
          Usuarios que utilicen la Plataforma a título no profesional pueden
          implementar las garantías de conformidad (artículos L211-4 y
          siguientes del Código del Consumidor) y defectos ocultos (artículo
          1641 y siguientes del Código. civil). <br /> <br /> Por otro lado, el
          Usuario renuncia expresamente a su derecho de desistimiento en
          aplicación de lo dispuesto en el artículo L121-21-8 1 ° del Código del
          Consumidor.
        </div>
      </div>
      <div>
        <H5>10. Inexécution fautive ou manquement grave aux CGUV</H5>
        <div css={{ marginBottom: 30 }}>
          En cas d’inexécution fautive ou de manquement grave à l’une quelconque
          des obligations incombant à l’Utilisateur et/ou au Client, au titre
          des présentes CGUV, le contrat sera résilié automatiquement et de
          plein droit trente jours (30) jours après une mise en demeure
          signifiée à l’Utilisateur et/ou au Client par lettre recommandée avec
          demande d’avis de réception indiquant l’intention de faire jouer la
          présente clause et restée sans effet.
        </div>
      </div>
      <div>
        <H5>10. No renuncia - integridad</H5>
        <div css={{ marginBottom: 30 }}>
          10.1. El hecho de que SHOOOTIN no reclame un fallo o un incumplimiento
          del Usuario en cualquiera de sus obligaciones contractuales o legales
          no puede interpretarse como una renuncia para aprovechar este fallo o
          este incumplimiento. . El hecho de que SHOOOTIN no reclame una
          estipulación de las CGUV no implica una renuncia al beneficio de dicha
          estipulación. <br /> <br /> 10.2. Se podrán realizar acuerdos
          específicos entre los Usuarios y SHOOOTIN. Estos acuerdos y estas CGUV
          forman un todo y constituyen los términos y condiciones completos de
          las disposiciones aplicables a su relación con SHOOOTIN. <br /> <br />{' '}
          En caso de cualquier contradicción entre dichos acuerdos y estas CGUV,
          los acuerdos prevalecerán.
        </div>
      </div>
      <div>
        <H5>12. Ley aplicable</H5>
        <div css={{ marginBottom: 30 }}>
          Las presentes CGUV se regirán por la ley francesa
        </div>
      </div>
      <div>
        <H5>13. Atribución de jurisdicción</H5>
        <div css={{ marginBottom: 30 }}>
          Tous les litiges entre SHOOOTIN et un Utilisateur, concernant tant la
          validité, l’interprétation, l’exécution, la résolution des présentes
          CGUV leurs conséquences et leurs suites et qui n'auraient pas pu être
          résolus à l'amiable entre SHOOOTIN et le Client, seront soumis à la
          compétence du Tribunal de Grande Instance de Paris si le Client est
          une personne physique ou à la compétence du Tribunal de Commerce de
          Paris si le Client est une personne morale. <br />
          <br />
          L’Utilisateur est informé qu'il peut en tout état de cause recourir à
          une médiation conventionnelle, notamment auprès de la Commission de la
          médiation de la consommation (C. cons. art. L 612-1) ou auprès des
          instances de médiation sectorielles existantes, ou à tout mode
          alternatif de règlement des différends (conciliation, par exemple) en
          cas de contestation.
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
