import React from 'react';
import { H5 } from '../../primitives/appTitle';

export const CguContentFrance = () => {
  return (
    <div>
      <div>
        <H5>1. Définitions :</H5>
        <div css={{ marginBottom: 30 }}>
          Client : désigne toute personne inscrite sur la Plateforme et qui
          commande par cet intermédiaire un Reportage auprès de SHOOOTIN.
          <br />
          <br />
          Commande : désigne la commande de réalisation d’un Reportage effectuée
          via la Plateforme de SHOOOTIN. <br />
          <br />
          Compte Personnel : désigne le compte dont dispose tout Utilisateur
          pour accéder à la Plateforme, y incluant l’ensemble des données
          personnelles relatives à un Utilisateur, notamment ses Identifiants,
          qui sont nécessaires pour accéder aux services de la Plateforme
          SHOOOTIN.
          <br />
          <br />
          Contenu : désigne le contenu obtenu et créé par un Prestataire dans le
          cadre d’un Reportage, incluant aussi bien du contenu photographique,
          musical, vidéo ou tout autre type d’image issue d’une technologie
          utilisée par le Prestataire.
          <br />
          <br />
          Identifiants : désigne l’adresse email de l’Utilisateur, ainsi que le
          mot de passe choisi par l’Utilisateur ou attribué par SHOOOTIN,
          permettant à l’Utilisateur de s’identifier afin d’accéder aux services
          de la Plateforme.
          <br />
          <br />
          Inscription : désigne la procédure d’inscription en ligne que
          l’Utilisateur doit accomplir sur la plateforme SHOOOTIN afin de
          pouvoir accéder à ladite plateforme. Cette Inscription conduit à la
          création des Identifiants et du Compte Personnel de l’Utilisateur.
          <br />
          <br />
          Plateforme : désigne la plateforme de SHOOOTIN accessible à l’adresse
          http://shoootin.com/, à partir de laquelle les Utilisateurs peuvent
          accéder à l’ensemble des services fournis par SHOOOTIN.
          <br />
          <br />
          Prestataire : désigne une personne exerçant à titre professionnel la
          profession de photographe, de vidéaste ou autre métier de l’image,
          chargée par SHOOOTIN de réaliser des Reportages. Les Prestataires sont
          choisis par SHOOOTIN sur candidature. <br />
          <br />
          Prestations : désignent l’ensemble des services proposés par SHOOOTIN
          aux Utilisateurs et / ou Clients.
          <br />
          <br />
          Reportage : désigne le reportage photographique, vidéo ou autre
          prestation de prise de vue quelle que soit la technologie utilisée,
          réalisé par un Prestataire.
          <br />
          <br />« STRIPE » désigne le service de paiement tiers
          (https://stripe.com/fr) utilisé par SHOOOTIN pour gérer les flux
          financiers entre les Utilisateurs, les Clients et SHOOOTIN. En
          acceptant les présentes Conditions Générales d’Utilisation, le Client
          accepte également les Conditions Générales d’Utilisation de STRIPE
          (https://stripe.com/en-fr/legal).
          <br />
          <br />
          Utilisateur : désigne toute personne inscrite sur la Plateforme.
        </div>
      </div>
      <div>
        <H5>2. Objet des CGUV et version en vigueur</H5>
        <div css={{ marginBottom: 30 }}>
          2.1. Les présentes CGUV ont pour objet de (i) définir les conditions
          dans lesquelles les Utilisateurs peuvent accéder à la Plateforme et
          l’utiliser et (ii) gérer les relations entre SHOOOTIN et les
          Utilisateurs.
          <br />
          <br />
          2.2. Toute personne qui accède à la Plateforme de SHOOOTIN s’engage à
          respecter, sans réserve, les présentes CGUV, qui sont notifiées aux
          Utilisateurs pour acceptation préalablement à leur Inscription à la
          Plateforme au moment de leur première commande d’un Reportage. <br />
          <br />
          2.3. SHOOOTIN est libre de modifier, à tout moment et sans préavis,
          les présentes CGUV, afin notamment de prendre en compte toute
          évolution légale, jurisprudentielle, éditoriale et/ou technique.
          SHOOOTIN en informera l’Utilisateur lors de sa prochaine connexion à
          la Plateforme, lequel devra valider, par le biais d’une case à cocher,
          la nouvelle version des CGUV. <br />
          <br />
          En tout état de cause, le fait pour l’Utilisateur de continuer à
          utiliser la Plateforme après l’information de la modification des CGUV
          entraîne l’acceptation des modifications des CGUV par l’Utilisateur.
          La version qui prévaut est celle qui est accessible en ligne.
          <br />
          <br />
          2.4. Les présentes CGUV sont applicables aux relations entre SHOOOTIN
          et les Utilisateurs.
        </div>
      </div>
      <div>
        <H5>3. Description du fonctionnement de SHOOOTIN</H5>
        <div css={{ marginBottom: 30 }}>
          3.1. Activité de SHOOOTIN
          <br />
          <br />
          SHOOOTIN est une plateforme par laquelle (i) les Clients commandent
          des Reportages de biens (notamment, sans que la liste ne soit
          limitative, appartements, maisons, bureaux, boutiques, restaurants,
          etc.) situés en Europe et aux USA, (ii) pour la réalisation desquels
          SHOOOTIN fait appel à des Prestataires.
          <br />
          <br />
          3.2. Commande et acceptation des Reportages
          <br />
          <br />
          3.2.1. A la première commande d’un Reportage, le Client crée son
          Compte Personnel, en indiquant les données personnelles requises, qui
          sont traitées conformément aux dispositions prévues aux présentes.{' '}
          <br />
          <br />
          Il est précisé que le Client a la possibilité de créer des
          sous-comptes pour d’autres Utilisateurs, avec des rôles définis. Dans
          cette situation, les Utilisateurs accèderont avec leur propre adresse
          email et leur propre mot de passe, au Compte Personnel de
          l’Utilisateur principal, qui les aura préalablement autorisés à
          disposer de tout ou partie des services de la Plateforme et/ou accéder
          à tout ou partie des informations dudit Compte Personnel. <br />
          <br />
          Le Client effectue une Commande via son Compte Personnel. Pour que
          celle-ci soit valide et prise en compte par SHOOOTIN, le Client doit
          obligatoirement indiquer le type de bien qui fera l’objet du
          Reportage, l’adresse dudit bien, le nombre de pièces qu’il comporte
          (uniquement pour les appartements et maisons), et sa surface (en
          mètres carrés), le type de Prestations qu’il choisit, ainsi que toute
          information complémentaire que l’Utilisateur estime nécessaire. Il est
          précisé que les informations demandées par SHOOOTIN sont susceptibles
          de varier en fonction de l’évolution de la Plateforme.
          <br />
          <br />
          Par défaut, il est indiqué que le Client donne rendez-vous au
          Prestataire en bas de l’immeuble ou plus généralement devant le bien
          concerné. Dans le cas contraire, le Client doit décocher la case
          prévue à cet effet et indiquer toute information nécessaire pour que
          le Prestataire puisse accéder au bien objet du Reportage, et
          notamment, le cas échéant, le code d’accès au bien. <br />
          <br />
          De même, il est indiqué par défaut que le Client sera sur place lors
          de la réalisation du Reportage. Dans le cas contraire, le Client doit
          décocher la case prévue à cet effet et indiquer les coordonnées du
          tiers qu’il a mandaté pour être sur place (propriétaire ou locataire
          du bien, gardien, etc.) afin de permettre au Prestataire d’accéder au
          bien objet du Reportage et d’assister audit Reportage. Ces coordonnées
          comprennent les nom, prénom et numéro de téléphone portable du tiers.{' '}
          <br />
          <br />
          Le Client s’engage à informer le tiers que les données susvisées sont
          collectées et traitées par SHOOOTIN, et que les droits dont il dispose
          sur ces données, tels que prévus à l’article 7 des présentes CGUV,
          peuvent être exercés directement auprès de SHOOOTIN, en lui indiquant
          l’adresse email à laquelle ces demandes peuvent être adressées. La
          transmission des données personnelles du tiers à SHOOOTIN est
          essentielle à la réalisation des Reportages, lorsque le Client ne
          souhaite pas être présent. <br />
          <br />
          Lors de la rédaction de sa Commande, le Client doit impérativement
          informer SHOOOTIN dans l’onglet « Informations complémentaires » si le
          bien objet du Reportage a été réalisé par un architecte ou architecte
          d’intérieur, compte tenu des implications en termes de propriété
          intellectuelle, ainsi que cela est indiqué à l’article 8 ci-après.{' '}
          <br />
          <br />A défaut, SHOOOTIN considérera que le bien en question n’a pas
          été réalisé par un architecte (ou architecte d’intérieur), et qu’en
          conséquence aucune des précautions particulières dont il est fait
          mention à l’article 8 ci-après n’est applicable. Toute conséquence
          liée à cette information ou au défaut de celle-ci relève de la
          responsabilité exclusive du Client . La responsabilité de SHOOOTIN ne
          pourra donc en aucun cas être engagée à ce titre. <br />
          <br />
          Le Client garantit la véracité et l’exactitude de la Commande qu’il a
          validée via la Plateforme. <br />
          <br />
          3.2.2. SHOOOTIN fait son affaire de trouver un Prestataire pour
          réaliser le Reportage.
          <br />
          <br />
          La Commande n’est pas susceptible de modification par les
          Prestataires. <br />
          <br />
          En revanche, le Client peut :<br />
          <br />
          annuler la Commande dans un délai de 12 (douze) heures maximum
          précédent la date du rendez-vous. Dans ce cas, le Client sera crédité
          sur, son Compte Personnel du montant de la Commande. En cas de future
          Commande, le Client pourra utiliser les crédits accumulés pour payer
          le Reportage commandé. <br />
          <br />
          annuler la Commande moins de 12 (douze) heures avant la date du
          rendez-vous. Dans cette hypothèse, aucun remboursement ne sera
          effectué par SHOOOTIN au titre de l’annulation, ce que le Client
          reconnaît et accepte expressément.
          <br />
          <br />
          modifier la Commande, et notamment la date du rendez-vous du
          Reportage, plus de 2 (deux) heures avant la date du rendez-vous sans
          qu’aucun frais supplémentaire ne soit engagé par le Client. <br />
          <br />
          L’Utilisateur ne peut pas modifier la Commande, et notamment la date
          du rendez-vous du Reportage, moins de 2 (deux) heures avant l’heure
          initialement prévue. Dans une telle hypothèse, la Commande sera
          automatiquement annulée et aucun remboursement ou indemnité ne sera dû
          par SHOOOTIN à l’Utilisateur. <br />
          <br />
          Si la réalisation du Reportage avait déjà été acceptée par un
          Prestataire, la réalisation du Reportage peut être attribuée à un
          autre Prestataire, si le Prestataire initial ne remplit plus les
          conditions de disponibilité, de périmètre géographique ou de tout
          élément précisé dans la Commande modifiée par le Client. <br />
          <br />
          Le Client garantit la véracité et l’exactitude de la Commande telle
          que mise en ligne sur la Plateforme Clients ou modifiée par la suite.
          En cas de non-respect d’une de ses obligations par le Client, SHOOOTIN
          se réserve la possibilité de suspendre ou de supprimer le compte du
          Client concerné.
          <br />
          <br />
          3.2.3. La confirmation de la prise en charge de la réalisation de la
          Commande par un Prestataire est confirmée par SHOOOTIN au Client, par
          l’envoi d’un email à l’adresse indiquée pour la création de son Compte
          Personnel. <br />
          <br />
          En cas de report de la Commande, le Client indique une nouvelle date à
          laquelle il souhaite que le Reportage soit réalisé. SHOOOTIN se charge
          de trouver un Prestataire susceptible de réaliser le Reportage. <br />
          <br />
          3.2.4. Le Client reçoit une réponse à la Commande qu’il a créée dans
          les meilleurs délais suivant sa mise en ligne sur la Plateforme, sauf
          dysfonctionnement imprévu sur lequel SHOOOTIN n’a pas de contrôle.{' '}
          <br />
          <br />
          3.2.5. Lorsqu’une Commande est confiée à un Prestataire, SHOOOTIN
          transmet les coordonnées du Prestataire au Client et inversement. La
          réalisation du Reportage s’effectuera conformément aux modalités de la
          Commande telles que précisées par le Client.
          <br />
          <br />
          <br />
          3.3. Réalisation des Reportages <br />
          <br />
          Le Prestataire réalise le Reportage aux date et heure spécifiées par
          le Client dans la Commande et selon les éventuels critères spécifiques
          qu’il a indiqués lors de la création et/ou de la modification de la
          Commande. <br />
          <br />
          Le Prestataire effectue au minimum le nombre d’éléments composant le
          Contenu commandé par le Client lors de la Commande, sauf en cas de
          demande particulière du Client. <br />
          <br />
          Le Reportage concerne uniquement les intérieurs et extérieurs
          (façades) du bien concerné. Aucune personne identifiable (passant,
          etc.) ne doit figurer sur le Contenu. Pour ce faire, la technique du
          floutage peut être utilisée, ou toute autre technique permettant de
          rendre une personne non identifiable. A défaut, le Contenu concerné
          réalisé par le Prestataire ne sera pas livré au Client. <br />
          <br />A la demande expresse du Client et sur autorisation écrite
          expresse de la personne photographiée recueillie par le Client, dans
          les conditions précisées ci-après à l’article 8.5, le Contenu peut
          représenter une personne physique identifiable dès lors que celle-ci
          est liée au lieu photographié (propriétaire, chef cuisinier, etc.).
          SHOOOTIN n’aura en aucun cas la charge de recueillir cette
          autorisation, qui devra être fournie par le Client à première demande.
          Le Client garantit SHOOOTIN sur ce point. <br />
          <br />
          Le Prestataire n’est pas chargé d’effectuer les retouches de
          post-production nécessaires après la réalisation de chaque Commande.
          SHOOOTIN réalise en interne les retouches de post-production sous
          réserve du respect du droit moral du Prestataire et de l’éventuel
          architecte qui a réalisé le bien concerné ainsi que du respect des
          droits des tiers.
          <br />
          <br />
          3.4. Validation des Reportages <br />
          <br />
          3.4.1. SHOOOTIN valide en interne le Reportage réalisé par le
          Prestataire, en contrôlant l’adéquation du Contenu envoyé par le
          Prestataire avec les critères spécifiques de la Commande précisés par
          le Client ainsi qu’avec le cahier des charges de SHOOOTIN. <br />
          <br />
          3.4.2. Si le Contenu n’est pas conforme au cahier des charges de
          SHOOOTIN et/ou aux critères spécifiés par le Client, SHOOOTIN informe
          le Prestataire par email. SHOOOTIN peut demander au Prestataire de
          réaliser à nouveau le Reportage, ce que le Prestataire reconnaît et
          accepte expressément. <br />
          <br />
          Dans ce cas, le Reportage sera réalisé aux date et heure indiquées par
          SHOOOTIN, sous réserve de la disponibilité du Client ou du tiers qu’il
          a mandaté, le Prestataire s’engageant à se rendre disponible. <br />
          <br />
          Si la réalisation d’un nouveau Reportage décale la date de remise du
          Contenu au Client, SHOOOTIN en informera le Client dans les meilleurs
          délais par email. <br />
          <br />
          3.5. Livraison du Reportage <br />
          <br />
          3.5.1. Dans les 48h ouvrées suivant la fin du Reportage, SHOOOTIN
          livre au Client le Contenu du Reportage via la Plateforme, sur
          laquelle ils sont téléchargeables par le Client en différents formats.
          Le Client est notifié de la disponibilité du Contenu par email. <br />
          <br />
          Dans l’hypothèse où le Contenu du Prestataire ne serait pas conforme
          au cahier des charges de SHOOOTIN et/ou aux critères du Client et que
          la réalisation d’un nouveau Reportage serait nécessaire, le délai de
          remise du Contenu sera reporté à une date ultérieure en fonction des
          disponibilités du Prestataire. <br />
          <br />
          3.5.2. En cas de défaillance du système informatique de la Plateforme
          lors de l’envoi par le Prestataire ou du téléchargement par SHOOOTIN
          ou le Client du Contenu du Reportage, le Client s’engage à en informer
          SHOOOTIN dans les meilleurs délais, par email. Dans ce cas, et sans
          attendre la réparation de la défaillance technique, le Contenu sera
          transféré par tout moyen alternatif, tel que par email, site de
          transfert, etc. En tout état de cause, SHOOOTIN ne saurait être tenue
          responsable du retard de transmission du Contenu dû à ladite
          défaillance technique. <br />
          <br />
          3.6. Suivi après la livraison du Reportage <br />
          <br />
          3.6.1. Le Client a accès au Contenu depuis la Plateforme pendant un
          délai de 7 (sept) jours à compter de sa mise en ligne. Il peut ainsi
          consulter le Contenu avant de le télécharger. Ainsi, le Client doit
          sélectionner le Contenu qu’il entend conserver (sauf si le nombre
          d’éléments composant le Contenu correspond à celui prévu dans la
          Commande du Client). Le téléchargement du Contenu via la Plateforme
          vaut validation du Reportage par le Client. Si jamais le Client
          souhaite contester tout ou partie du Contenu, il ne doit en aucun cas
          les sélectionner ni les télécharger. Aucune contestation ne peut être
          effectuée par le Client après qu’il ait téléchargé tout ou partie du
          Contenu. Dans une telle hypothèse, la contestation ne sera pas
          valable. Le Client ne pourra pas rechercher la responsabilité de
          SHOOOTIN sur ce fondement. <br />
          <br />
          La réclamation du Client doit s’effectuer par email auprès de SHOOOTIN
          sur des critères de qualité photographique, vidéographique et
          technique objectifs, tels que le nombre des éléments composant le
          Contenu, le cadrage, les niveaux de luminosité, etc.
          <br />
          <br />
          En cas de contestation du Reportage par le Client (avant tout
          téléchargement du Contenu), sa réclamation est transmise immédiatement
          à SHOOOTIN. Il sera dans ce cas impossible au Client de télécharger le
          Contenu contesté, qu’il ne pourra pas utiliser quelle que soit la
          finalité, le moyen et le support envisagés, ce qu’il reconnaît et
          accepte expressément. <br />
          <br />
          3.6.2. SHOOOTIN étudie dans les meilleurs délais la réclamation du
          Client. <br />
          <br />
          Plusieurs options sont possibles : (i) SHOOOTIN peut rejeter la
          réclamation si celle-ci n’est pas basée sur des critères
          photographiques ou vidéographiques techniques et objectifs. Le Client
          conservera dans ce cas le Reportage tel qu’il lui a é été livré, (ii)
          la réclamation est considérée comme fondée par SHOOOTIN, dans ce cas
          le Client peut demander à ce que le Reportage soit réalisé de nouveau
          par le Prestataire, dans les conditions énoncées ci-avant, ou à être
          crédité via son Compte Personnel de la somme versée au titre de la
          Commande pour la réalisation d’un autre Reportage. Le montant de la
          Commande ne pourra pas être remboursé au Client.
          <br />
          <br />
          En cas de contestation, aucun Contenu issu du Reportage livré par
          SHOOOTIN au Client et que ce dernier a contesté ne pourra être
          téléchargé ni utilisé par le Client, en tout ou partie, par quelque
          moyen que ce soit, à quelque fin que ce soit et sur quelque support
          que ce soit. <br />
          3.6.3. En cas de nouveau Reportage, le processus de validation est le
          même que celui énoncé aux présentes pour tout Reportage.
          <br />
          <br />
          Si l’Utilisateur n’est pas satisfait du nouveau Reportage, sous
          réserve qu’il n’ait téléchargé aucun cliché de ce nouveau Reportage,
          et si cette contestation est basée sur des critères objectifs,
          SHOOOTIN s’engage à créditer en totalité ou partiellement le Compte
          Personnel du Client . . En cas de future Commande, le Client pourra
          utiliser les crédits accumulés pour payer le Reportage commandé.{' '}
          <br />
          <br />
          3.7. Autres prestations <br />
          <br />
          SHOOOTIN est également susceptible de proposer de nouvelles
          prestations de prises de vue pour réaliser des Reportages
          vidéographiques (article 3.7.1), des visites immersives 3D basées sur
          la technologie Matterport (article 3.7.2) ainsi que le recours aux
          drones (article 3.7.3).
          <br />
          <br />
          3.7.1. Les Prestations vidéographiques seront réalisées selon les
          modalités exposées aux présentes. Ces Prestations comprennent les
          prestations accessoires de montage, d’étalonnage (travail de
          couleurs), et l’insertion d’éléments graphiques (texte ou logo).
          <br />
          <br />
          Ces Prestations porteront sur la réalisation d’un Contenu
          vidéographique d’une durée moyenne d’1 minute et 30 secondes, sauf
          accord.
          <br />
          <br />
          Si le Client souhaite intégrer une musique au Contenu vidéographique,
          ce dernier aura le choix entre trois options distinctes :<br />
          <br />
          le Client fournit le contenu musical à SHOOOTIN, qui l’intègre. Dans
          cette hypothèse, le Client s’acquittera de l’ensemble des obligations
          administratives et comptables, et garantira SHOOOTIN qu’il dispose de
          l’ensemble des droits nécessaires en vue de son exploitation ;<br />
          <br />
          le Client fait appel à SHOOOTIN en vue de composer et réaliser un
          contenu musical intégré à un Reportage réalisé par un Prestataire.
          Dans cette hypothèse, SHOOOTIN confiera cette mission à un partenaire
          prestataire et fournira le contenu au Client, après qu’il l’ait
          validé, en contrepartie du paiement d’une somme facturée par SHOOOTIN,
          qui s’acquittera des droits auprès des ayants-droit ;<br />
          <br />
          le Client fait appel à SHOOOTIN en vue de composer et réaliser un
          contenu musical qu’il pourra librement exploiter dans le cadre de tout
          contenu de son choix . Dans cette hypothèse, SHOOOTIN confiera cette
          mission à un partenaire prestataire et fournira le contenu au Client,
          après qu’il l’ait validé, en contrepartie du paiement d’une somme
          facturée par SHOOOTIN, qui s’acquittera des droits auprès des
          ayants-droit.
          <br />
          <br />
          Le Contenu issu d’une Prestation vidéographique sera livré au Client
          dans un délai de 72 (soixante-douze) heures suivant la fin de la
          mission confiée au Prestataire.
          <br />
          <br />
          3.7.2. Les Prestations de visites 3D sont basées sur la technologie
          dite Matterport, permettant de réaliser une visite immersive d’un lieu
          en intérieur. Sur demande du Client, et sous réserve de validation par
          SHOOOTIN, la visite 3D peut également comprendre un plan 360° d’un
          lieu en extérieur.
          <br />
          <br />
          SHOOOTIN propose plusieurs options aux Clients : la visite immersive
          peut être réalisée avec ou sans l’assistance de la technologie 3D, un
          plan 2D peut également être fourni en supplément, et il est également
          possible d’envisager l’intégration de données 3D.
          <br />
          <br />
          S’agissant d’une technologie particulière, le Client accepte et
          comprend que les résultats obtenus dans le cadre d’une telle
          Prestations peuvent varier en fonction de circonstances indépendantes
          de la volonté de SHOOOTIN, qui ne peut donc garantir un résultat
          identique à celui souhaité par le Client, ce que ce dernier accepte
          sans réserve.
          <br />
          <br />
          Plus précisément, la modélisation du Contenu issu de la visite
          immersive dépend des outils du prestataire Matterport.
          <br />
          <br />
          Dès que ce prestataire a finalisé la modélisation, il en avertit
          SHOOOTIN dans un délai de 48 (quarante-huit) heures après l’envoi du
          Contenu réalisé le Prestataire.
          <br />
          <br />
          Dès réception du Contenu modélisé, SHOOOTIN adressera au Client un
          lien internet à partir duquel il pourra consulter ledit Contenu.
          <br />
          <br />
          Le Client est averti que chaque prestation de modélisation réalisée
          par Matterport est facturée par cette dernière à SHOOOTIN, qui en
          répercutera le coût au Client, dans les conditions qui lui seront
          exposées.
          <br />
          <br />
          Ne réalisant pas cette prestation de stockage, SHOOOTIN n’est pas
          responsable de la perte ou de l’altération dudit Contenu, ce que le
          Client comprend et accepte sans réserve.
          <br />
          <br />
          Le Client est averti qu’une telle Prestation requiert un important
          délai de prises de vue par le Prestataire, en fonction de la surface
          du bien objet du Reportage. Dès lors, le Client est informé qu’il
          devra se rendre disponible, ou mandater un tiers qui devra se rendre
          disponible, afin de permettre au Prestataire de finaliser sa
          Prestation. A défaut, si le Client ou le tiers mandaté était amené à
          quitter les lieux avant que le Prestataire n’ait finalisé, le Client
          devra passer une autre Commande, et s’acquitter d’un nouveau montant,
          afin de lui permettre de finaliser.
          <br />
          <br />
          Le Client est informé que SHOOOTIN se réserve la possibilité de
          facturer une somme complémentaire, si elle s’aperçoit que la surface
          du lieu objet du Reportage est supérieure à ce qui avait été annoncé
          préalablement, ou que le lien comporte un nombre plus important de
          modèles ou d’éléments à modéliser.
          <br />
          <br />A ce titre, le Client comprend l’importance de fournir à
          SHOOOTIN des informations complètes, précises et conformes à la
          réalité.
          <br />
          <br />
          En cas de difficulté liée un problème technique lié notamment à
          l’alignement des lieux modélisés ne relevant pas de la responsabilité
          de SHOOOTIN ou du Prestataire, le Client pourra solliciter
          l’intervention du Prestataire afin qu’il réalise de nouvelles prises
          de vue sous réserve de s’acquitter du paiement d’une somme
          supplémentaire.
          <br />
          <br />
          3.7.3. SHOOOTIN propose également des Prestations de prises de vue par
          drone.
          <br />
          <br />
          Ces missions peuvent consister en des Prestations de Contenu
          photographique, de Contenu vidéographique, de relevés
          photogramétriques (3D), ou de relevés thermiques.
          <br />
          <br />
          Ces missions seront réalisées conformément aux modalités concernant
          les Reportages photographiques ou vidéographiques sous réserve des
          particularités spécifiées par SHOOOTIN.
          <br />
          <br />
          Le Client est toutefois informé que SHOOOTIN ne peut garantir de
          créneau d’intervention, ni garantir l’exécution du Reportage, compte
          tenu des contraintes notamment météorologiques et administratives.{' '}
          <br />
          <br />
          Dans l’hypothèse où le Client annulerait la Commande ou la modifierait
          (notamment s’agissant de la date de la prise de vue), alors qu’une
          demande d’autorisation de prise de vue par drone aurait d’ores et déjà
          été effectuée par SHOOOTIN auprès de la préfecture, le Client sera
          redevable à l’égard de SHOOOTIN d’une indemnité de 100 € hors taxes. .
          <br />
          <br />
          Dès lors, chaque Commande d’une telle Prestation fera l’objet d’un
          accord particulier entre SHOOOTIN et le Client au sujet des conditions
          d’intervention et des garanties pouvant être apportées par SHOOOTIN.
          <br />
          <br />
          3.8. Modalités de paiement des Reportages par les Clients
          <br />
          <br />
          3.8.1. Le Client dispose de plusieurs options pour payer le coût (y
          incluant les taxes en vigueur) de la Commande : <br />
          <br />
          Par paiement au comptant, par carte bancaire au moment de la Commande,
          ce qui permet de valider les date et heure du rendez-vous indiqués par
          le Client pour la réalisation du Reportage;
          <br />
          Via un Compte Personnel prépayé : dans ce cas, le Compte Personnel du
          Client est prélevé du montant de la Commande qu’il a effectuée ;<br />
          Par facturation personnalisée : le Client commande le nombre de
          Reportages qu’il souhaite, et est facturé à la fin de chaque mois.
          Dans ce cas, il devra s’acquitter de la facture par prélèvement
          automatique sur un compte bancaire en retournant à SHOOOTIN un mandat
          SEPA dûment complété et signé. Chaque facture sera réglée dans un
          délai maximal de 7 (sept) jours à compter de son émission par SHOOOTIN
          ;<br />
          Grands comptes : dans ce cas, il est possible de prévoir des modalités
          de règlement personnalisées, convenues d’un commun accord entre le
          Client et SHOOOTIN par écrit, ces écrits faisant partie intégrante des
          présentes CGUV. <br />
          <br />
          Le Client peut opter pour différents modes de règlement : paiement par
          carte bancaire dans les conditions prévues aux présentes ou par
          prélèvement SEPA. Le paiement par chèque n’est pas accepté.
          <br />
          <br />
          Le prix dû par le Client au titre de la réalisation d’un Reportage
          varie en fonction des Prestations echoisies par le Client, dont il est
          informé lorsqu’il remplit les critères de la Commande. <br />
          <br />
          Le Client peut acquérir plus de Contenu que le nombre initialement
          prévu dans la Commande (sauf si le nombre d’éléments composant le
          Contenu livré par le Prestataire correspond au nombre prévu dans la
          Commande), au moment où il sélectionne puis télécharge le Contenu sur
          la Plateforme. Dans ce cas, un coût additionnel par nouveau Contenu
          lui est facturé par SHOOOTIN, selon les tarifs en vigueur à la date de
          la Commande, payable dans les mêmes conditions que ci-dessus.
          <br />
          <br />
          Les paiements relatifs aux Commandes de Reportages et en cas
          d’approvisionnement des Comptes Personnels des Clients, sont effectués
          via le prestataire de paiement STRIPE. <br />
          <br />
          Dans l’hypothèse d’un règlement par carte bancaire :<br />
          <br />
          si le Client a enregistré son numéro de carte bancaire dans son Compte
          Personnel, étant rappelé que SHOOOTIN ne conserve pas de numéro de
          carte bancaire, les opérations de débit étant gérées par le
          prestataire STRIPE ;<br />
          <br />
          si le Client a enregistré son numéro de carte et a opté pour un
          système de facturation mensuelle, étant rappelé que SHOOOTIN ne
          conserve pas de numéro de carte bancaire, les opérations de débit
          étant gérées par le prestataire STRIPE ;<br />
          <br />
          si le Client n’a pas enregistré de numéro de carte bancaire dans son
          Compte Personnel, SHOOOTIN sera amenée à lui demander de fournir ce
          numéro, qu’il ne conservera pas, puis à lui demander de communiquer un
          identifiant permettant de le débiter via le prestataire STRIPE, chargé
          des opérations de débit ; <br />
          <br />
          Les modalités de paiement sont propres à la plateforme STRIPE, et sont
          indépendantes de SHOOOTIN. SHOOOTIN ne conserve aucune coordonnée
          bancaire du Client. SHOOOTIN ne saurait être tenue responsable d’un
          quelconque dysfonctionnement survenant lors du paiement. Sa
          responsabilité ne pourra être engagée à ce titre. <br />
          <br />
          Toutefois, l’outil STRIPE étant utilisé dans le cadre de l’utilisation
          des services de la Plateforme SHOOOTIN, il est entendu que SHOOOTIN
          doit être considérée comme responsable de traitement des données
          personnelles collectées dans le cadre des opérations de paiement
          effectuées dans le cadre des présentes. Lesdites données personnelles
          sont traitées conformément à l’article 7 ci-après. <br />
          <br />
          En acceptant les présentes CGUV, les Clients acceptent également
          d’être liés aux Conditions Générales d’Utilisation de STRIPE.
          Celles-ci peuvent faire l’objet de modifications de la part de STRIPE.
          Il est précisé qu’en aucun cas SHOOOTIN ne peut modifier les
          Conditions Générales d’Utilisation de STRIPE, qui est exclusivement un
          prestataire, sur lequel il n’a aucun contrôle. <br />
          <br />
          3.8.2. Si le Prestataire se désiste de la Commande moins de 24 heures
          avant la date et l’heure prévues et qu’aucun autre Prestataire n’est
          susceptible de réaliser la Commande dans les conditions et modalités
          indiquées par le Client, le montant de la Commande est crédité sur le
          Compte Personnel du Client, qu’il pourra utiliser pour une autre
          Commande. <br />
          <br />
          3.8.3. Tout retard de paiement à l’échéance indiquée ci-avant, après
          mise en demeure restée infructueuse pendant un délai de 7 (sept) jours
          à compter de la réception, fera courir des intérêts au taux de 15 %.
          Les pénalités de retard sont exigibles sans qu’un rappel ne soit
          nécessaire. Tout retard de paiement d’une créance entrainera outre les
          pénalités de retard, une indemnité forfaitaire pour frais de
          recouvrement de quarante (40) euros.
          <br />
          <br />
          3.8.4. Dans l’hypothèse où SHOOOTIN sera amenée à rembourser un
          Client, les sommes remboursées seront alors converties en crédits, que
          le Client sera libre d’utiliser librement en vue d’une nouvelle
          Commande, et ce pendant une durée maximale d’un an.
          <br />
          <br />
          3.9. Modalités relatives au fonctionnement général de la Plateforme
          <br />
          <br />
          3.9.1. SHOOOTIN est un prestataire de services, dont le rôle est de
          régir les relations entre Prestataires et Clients, y incluant
          notamment le choix du Prestataire, le suivi de la réalisation des
          Reportages, la réalisation de la retouche post-production, ainsi que
          la livraison des Reportages aux Clients. Les Clients représentent
          leurs propres intérêts : ils délivrent ou acceptent que leurs soient
          fournis des Reportages sous leur seule et entière responsabilité.{' '}
          <br />
          <br />
          3.9.2. Les Prestataires sont des professionnels, ce qui signifie
          qu’ils ont un diplôme ou un titre relatif à l’exercice de la
          profession de photographe ou vidéographe, ou disposent tout au moins
          d’une expérience significative à titre professionnel dans ces
          domaines. <br />
          <br />A ce titre, SHOOOTIN garantit au Client avoir sélectionné le
          Prestataire sur la base de critères objectifs. En revanche, SHOOOTIN
          ne peut garantir au Client que le Reportage confié au Prestataire soit
          parfaitement conforme au cahier des charges de SHOOOTIN et/ou aux
          critères qu’il a spécifiés au moment de la Commande. Il est entendu
          que dans ce cas, les dispositions des présentes CGUV relatives à cette
          situation seront appliquées. <br />
        </div>
      </div>
      <div>
        <H5>4. Limitation de responsabilité</H5>
        <div css={{ marginBottom: 30 }}>
          4.1. Le Client reconnaît être informé et accepte le fait que SHOOOTIN
          ne peut être, à aucun titre, tenue pour responsable de la création, de
          la modification, de la suppression, ou du défaut de réception,
          d’émission, de transmission ou de stockage de données des Clients,
          relevant de l’unique et entière responsabilité de ces derniers. Les
          Commandes des Clients sont mises en ligne sur la Plateforme sous leur
          seule responsabilité. Les Clients garantissent SHOOOTIN contre toute
          réclamation et tout recours de toute personne physique ou morale qui
          s’estimerait lésée par le contenu publié.
          <br />
          <br />
          De même, SHOOOTIN ne peut être, à aucun titre, tenue pour responsable
          du retard ou de la défaillance du serveur ou du réseau internet
          concernant l’envoi des Reportages aux Clients et/ou leur
          téléchargement depuis la Plateforme. <br />
          <br />
          4.2. Le fonctionnement des services proposés par SHOOOTIN dépend
          majoritairement de facteurs qui ne sont pas sous le contrôle de
          SHOOOTIN, notamment s’agissant des compétences et qualités
          relationnelles des Prestataires, ce que SHOOOTIN ne peut garantir, et
          dont cette dernière ne pourra en aucun cas être tenue responsable.
          <br />
          <br />
          SHOOOTIN ne fournit aucun autre service et ne garantit aucunement, que
          ce soit de manière directe ou indirecte, la véracité et l’exactitude
          des Commandes (y compris les informations et caractéristiques
          relatives aux biens) mises en ligne par les Clients sur la Plateforme.
          <br />
          <br />
          SHOOOTIN décline donc toute responsabilité quant au contenu des
          Commandes publiées par les Utilisateurs et quant au comportement des
          Utilisateurs. Etant donné que le contenu des Commandes postées sur la
          Plateforme est généré par les Utilisateurs eux-mêmes, SHOOOTIN ne
          saurait être tenue responsable de la véracité des annonces ou de la
          légitimité des commandes de Reportages, ni des termes et du contenu
          des Commandes. <br />
          <br />
          La responsabilité de SHOOOTIN ne pourra donc aucunement être engagée,
          de manière directe ou indirecte, à ce titre. <br />
          <br />
          4.3. SHOOOTIN n’est pas responsable en cas de défectuosité, vice
          caché, défaut de conformité du matériel utilisé par le Prestataire
          pour réaliser le Reportage, ou en cas d’abus, de déloyauté ou de
          fraude émanant d’un Client ou d’un tiers, ou de toute autre faute
          délictueuse ou infraction commise par un autre Utilisateur ou un tiers
          à l’occasion de l’utilisation de la Plateforme.
          <br />
          <br />
          4.4. SHOOOTIN ne fournit aucune garantie expresse ou implicite, en ce
          compris, sans que cette énumération ne soit limitative, relative à la
          continuité et à la pérennité de la Plateforme, à la performance de la
          Plateforme, à la conformité ou à la compatibilité de la Plateforme à
          un usage spécifique, à la qualité ou à l’absence de vice ou de défaut
          de la Plateforme, à l’éviction et à la non violation des lois et
          règlements ou des présentes CGUV de la Plateforme par d’autres
          Utilisateurs.
          <br />
          <br />
          4.5. Aucun conseil et aucune information, qu’ils soient oraux ou
          écrits, obtenus par l’Utilisateur de la part de SHOOOTIN ou lors de
          l’utilisation de la Plateforme, ne sont susceptibles de créer de
          garanties non expressément prévues par les présentes CGUV.
          <br />
          <br />
          4.6. L’Utilisateur reconnaît que son utilisation de la Plateforme se
          fait à ses risques et périls. La Plateforme lui est fournie « en
          l’état » et est accessible sans aucune garantie de disponibilité et de
          régularité. SHOOOTIN fera ses meilleurs efforts pour rendre la
          Plateforme accessible 24 heures sur 24, sept jours sur sept, sauf en
          cas de force majeure ou d’un événement hors du contrôle de SHOOOTIN et
          sous réserve des périodes de maintenance, des pannes éventuelles, des
          aléas techniques liés à la nature du réseau ou des actes de
          malveillance ou toute atteinte portée au matériel ou aux logiciels de
          SHOOOTIN.
          <br />
          <br />
          4.7. SHOOOTIN ne pourra en aucun cas être tenue pour responsable en
          raison d’une interruption de tout ou partie de la Plateforme quelle
          que soit la cause, la durée ou la fréquence de cette interruption.
          <br />
          <br />
          4.8. SHOOOTIN ne pourra en aucun cas être tenue pour responsable en
          cas de perte, vol, casse ou tout autre événement susceptible
          d’affecter l’état et/ou la possession des biens du Client lors de la
          réalisation du Reportage par le Prestataire. Dans ce cas, c’est la
          responsabilité civile professionnelle du Prestataire qui doit être
          mise en œuvre, dont le Client fait son affaire. <br />
          <br />
          De même, SHOOOTIN ne pourra pas être tenue pour responsable des actes
          et faits des tiers mandatés par les Clients intervenant au cours des
          Reportages, dont les Clients font leur affaire. SHOOOTIN ne pourra pas
          non plus être tenue pour responsable des conditions de réalisation des
          Reportages difficiles (chantiers, etc.). <br />
          <br />
          4.9. SHOOOTIN n’intervient en aucun cas au moment de la réalisation du
          Reportage lui-même. Ainsi, aucune responsabilité ne peut lui être
          imputée sur ce fondement, que ce soit notamment concernant les
          conditions de réalisation du Reportage ou les trajets nécessaires pour
          le réaliser. Ceci est parfaitement indépendant de la volonté de
          SHOOOTIN, ce que reconnaissent et acceptent expressément le Client et
          le Prestataire. <br />
          <br />
          Il est précisé également que lorsque le Client n’a pas souhaité être
          présent lors du Reportage, le tiers mandaté par le Client pour y
          assister et permettre au Prestataire d’accéder au bien n’a aucun lien
          direct ou indirect, de quelque sorte que ce soit, avec SHOOOTIN. La
          seule personne avec laquelle ce tiers est en contact et/ou en relation
          commerciale est le Client. <br />
          <br />
          Ainsi, dans l’hypothèse où un événement surviendrait durant la
          réalisation du Reportage et qui concernerait ou impliquerait le tiers
          en question, cet événement ne saurait engager la responsabilité de
          SHOOOTIN à quelque titre que ce soit sur ce fondement. <br />
          <br />
          Il est rappelé à ce titre que SHOOOTIN ne saurait être responsable des
          éventuelles difficultés dans la relation liant les Utilisateurs hors
          du cadre prévu par les présentes CGUV, que ce soit avec les
          Prestataires, les Clients, ou avec les éventuels tiers, qu’il s’agisse
          notamment des locataires ou propriétaires des biens concernés, ou des
          tiers mandatés par les Clients pour assister au Reportage.
          <br />
          <br />
          4.10. Il appartient au Client de respecter les obligations légales
          liées à la réalisation d’un Reportage, par exemple, le cas échéant et
          sans que cette liste soit limitative, les déclarations
          administratives, fiscales et sociales obligatoires découlant de
          l’exécution du Reportage. <br />
          <br />
          Ainsi, il appartient aux Clients de respecter les obligations légales
          résultant de la perception d’une somme d’argent, notamment
          l’éventuelle déclaration des revenus ainsi perçus, ainsi que, le cas
          échéant, les obligations fiscales, notamment la collecte de la TVA.
          <br />
          <br />
          4.11. Il est convenu que dans l’hypothèse où la responsabilité de
          SHOOOTIN est mise en cause, quel que soit le fondement et/ou la nature
          de l’action, que seuls les dommages directs sont susceptibles de
          donner lieu à réparation, que tous dommages indirects, consécutifs
          et/ou accessoires, tels que par exemple un trouble commercial, une
          perte de clientèle, etc., n’ouvriront pas droit à réparation au profit
          du Prestataire et/ou du Client. <br />
          <br />
          En tout état de cause, la responsabilité de SHOOOTIN est limitée, quel
          que soit le fondement et/ou la nature de l’action engagée à son
          encontre, au montant net perçu par SHOOOTIN dans le cadre des
          présentes. <br />
        </div>
      </div>
      <div>
        <H5>5. Utilisation de la Plateforme – Obligations des Utilisateurs</H5>
        <div css={{ marginBottom: 30 }}>
          5.1. L’Utilisateur s’engage à respecter les lois en vigueur dans le
          cadre de l’utilisation de la Plateforme. L’Utilisateur s’engage
          notamment à ne pas formuler de propos discriminatoires, racistes,
          xénophobes, antisémites, ni d’insultes, injures ou autres propos
          violents ou à caractère pornographique, ni à publier de contenu
          contraire à l’ordre public ou aux bonnes mœurs, que ce soit notamment
          sur son Compte Personnel ou sur les Commandes mises en ligne par les
          Clients. <br />
          <br />
          Les Utilisateurs s’interdisent par ailleurs d’utiliser la Plateforme
          dans un but promotionnel.
          <br />
          <br />
          5.2. Les Clients s’engagent expressément à utiliser la Plateforme pour
          toute commande et réalisation de Reportage d’un bien tel que défini
          aux présentes CGUV. De manière générale, les Utilisateurs s’engagent à
          se comporter de manière loyale vis-à-vis de SHOOOTIN, en ne cherchant
          pas à se démarcher ou se solliciter mutuellement pour des prestations
          de Reportages futures. <br />
          <br />
          Ainsi, le Prestataire s’interdit d’accepter toute Commande de la part
          de Clients en dehors du processus prévu par la Plateforme.
          <br />
          <br />
          De même, le Client s’engage à ne pas proposer de Commande aux
          Prestataires, en dehors du processus prévu par la Plateforme. <br />
          <br />
          Si un tel cas se produit, l’Utilisateur concerné s’oblige à en
          informer SHOOOTIN dans les meilleurs délais par email à l’adresse
          contact@shooot.in, qui prendra toutes les mesures nécessaires à
          l’encontre de l’Utilisateur impliqué, allant jusqu’à son exclusion de
          la Plateforme et la suppression de son Compte Personnel pour une durée
          déterminée, ou de manière définitive, cette décision étant prise à la
          discrétion de SHOOOTIN. L’Utilisateur s’engage à ne pas contester
          cette décision, par quelque moyen que ce soit. <br />
          <br />
          Dans une telle hypothèse, SHOOOTIN se réserve en outre la possibilité
          de poursuivre en justice toute personne qui contreviendrait à cette
          interdiction essentielle et déterminante des présentes CGUV. <br />
          <br />
          5.3. En acceptant les présentes CGUV, Le Client garantit qu’il dispose
          de toutes les autorisations nécessaires, notamment de la part du
          propriétaire et/ou du locataire du ou des bien(s) concerné(s), ainsi
          que de l’architecte qui a réalisé les plans du bien en question ainsi
          que de l’architecte d’intérieur, pour que le Prestataire réalise le
          Reportage et pour que le Contenu soit livré et utilisé dans les
          conditions énoncées aux présentes. Le but étant de réaliser les
          Reportages et d’utiliser le Contenu qui en résulte dans le respect des
          droits d’auteurs, du droit à l’image des personnes et des biens, et en
          conformité avec les lois et règlements en vigueur. <br />
          <br />
          Il est expressément entendu que le Client fait son affaire d’obtenir
          lesdites autorisations, directement ou par l’intermédiaire des
          locataires et/ou propriétaires des biens qui vont être photographiés,
          cette responsabilité ne revenant en aucun cas à SHOOOTIN. Le Client
          fournira copie de ces autorisations à première demande de SHOOOTIN. La
          responsabilité de SHOOOTIN ne pourra en aucun cas être engagée à ce
          titre. <br />
          <br />
          Ainsi, le Client s’engage à ne proposer de Commandes sur la Plateforme
          que pour des biens pour lesquels il détient les autorisations
          nécessaires pour la réalisation de Reportages, ainsi que pour
          l’utilisation de ces Reportages conformément aux présentes. Il est
          rappelé aux Clients que les articles L. 335-2 et suivants du Code de
          la Propriété Intellectuelle sanctionnent le délit de contrefaçon par
          trois ans d'emprisonnement et 300.000 euros d'amende.
          <br />
          <br />
          Le Client s’engage à ne pas publier de Commandes pour des Reportages
          destinés à une autre fin que celle visée dans les présentes CGUV,
          quelle qu’elle soit. <br />
          <br />
          La responsabilité de SHOOOTIN ne pourra donc en aucun cas être engagée
          à ce titre. <br />
          <br />
          5.4. Il est rappelé aux Utilisateurs que l’utilisation de SHOOOTIN à
          des fins professionnelles suppose le respect d’un certain nombre
          d’obligations légales, et notamment des obligations de déclaration
          auprès de l’administration, dont les Utilisateurs font leur affaire.
          La responsabilité de SHOOOTIN ne saurait être engagée en cas de
          manquement par les Utilisateurs aux obligations qui leur incombent eu
          égard à l’utilisation qu’ils font de la Plateforme. Notamment, il est
          rappelé que l’article L. 8224-1 du Code du travail punit le travail
          dissimulé par dissimulation d’activité ou par dissimulation d’emploi
          salarié d’un emprisonnement de trois ans et d’une amende de 45.000
          euros.
          <br />
          <br />
          5.5. Lors de son Inscription en ligne sur la Plateforme, l’Utilisateur
          s’engage à fournir des informations vraies, exactes, à jour et
          complètes sur son identité. L’Utilisateur s’engage à mettre
          immédiatement à jour en cas de modification les données qu’il a
          communiquées lors de son Inscription en ligne. Il est rappelé aux
          Utilisateurs que l’article 226-4-1 du Code Pénal punit d’un an
          d’emprisonnement et de 15.000 € d’amende le fait d’usurper l’identité
          d’un tiers ou de faire usage d’une ou plusieurs données de toute
          nature permettant de l’identifier en vue de troubler sa tranquillité
          ou celle d’autrui, ou de porter atteinte à son honneur ou à sa
          considération. L’Utilisateur qui constate un ou des faits constitutifs
          du délit d’usurpation d’identité sur la plateforme de SHOOOTIN doit
          immédiatement en informer SHOOOTIN à l’adresse suivante :
          contact@shooot.in. <br />
          <br />
          L’Utilisateur est seul responsable de l’utilisation qui est faite de
          son Compte Personnel ainsi que de la protection de ses Identifiants et
          mots de passe. Toute usurpation d’identité, perte, détournement ou
          utilisation non autorisée des Identifiants et/ou du Compte Personnel
          d’un Utilisateur et leurs conséquences, relèvent de la seule
          responsabilité de cet Utilisateur. Dans tous les cas mentionnés
          ci-dessus, l’Utilisateur est tenu d’en avertir SHOOOTIN, sans délai,
          par message électronique, précisant ses nom, prénoms, code postal,
          ville, date de naissance, téléphone, l’adresse électronique utilisée
          pour son Compte Personnel à l’adresse suivante, contact@shooot.in,
          pour permettre à SHOOOTIN de prendre des mesures afin de remédier à la
          situation, et notamment de procéder à l’annulation et/ou à la mise à
          jour immédiate(s) de l’Identifiant concerné et/ou du mot de passe
          concerné. L’utilisation abusive de cette faculté de signalisation est
          susceptible d'entraîner la responsabilité de celui qui en abuse.
          <br />
          <br />
          5.6. L’utilisation de SHOOOTIN est interdite aux mineurs et aux
          personnes ne disposant pas de la capacité de contracter, ou ne
          bénéficiant pas de l’autorisation d’un tuteur ou bien d’un curateur.
          L’utilisation de SHOOOTIN est interdite aux Utilisateurs dont le
          Compte Personnel a été volontairement suspendu ou supprimé par
          SHOOOTIN, pour la raison et la durée déterminée ou indéterminée
          indiquée à l’Utilisateur par SHOOOTIN.
          <br />
          <br />
          5.7. En cas de connaissance d’agissement manifestement illicite d’un
          Utilisateur et/ou en cas de violation des stipulations des présentes
          CGUV et/ou de toute obligation légale ou réglementaire, SHOOOTIN
          pourra, immédiatement, sans préavis ni indemnité, supprimer, suspendre
          ou reporter toute Commande concernée et/ou suspendre ou résilier le
          Compte Personnel de l’Utilisateur et lui refuser l’accès, de façon
          temporaire ou définitive à tout ou partie de la Plateforme. SHOOOTIN
          se réserve le droit de poursuivre devant les autorités judiciaires
          compétentes toute utilisation de la Plateforme qui ne serait pas
          conforme aux présentes CGUV. Dans l’hypothèse où le Compte Personnel
          du Client comporte du crédit non utilisé par ce dernier, SHOOOTIN
          s’engage à lui verser les sommes concernées dans les plus brefs
          délais, aux coordonnées bancaires qu’il indiquera au moment de la
          notification de la suppression de son Compte Personnel.
        </div>
      </div>
      <div>
        <H5>6. Assurances</H5>
        <div css={{ marginBottom: 30 }}>
          6.1. L’Utilisateur garantit SHOOOTIN qu’il dispose des assurances
          nécessaires (dommages aux biens et dommages corporels) permettant de
          couvrir les événements susceptibles de se produire pendant la
          réalisation du Reportage à l’intérieur et/ou à l’extérieur du bien
          concerné.
          <br />
          <br />
          6.2. Les Utilisateurs déclarent également être couverts par une
          assurance de responsabilité civile, couvrant tous dommages corporels
          (agressions ou accidents notamment) ou matériels susceptibles de se
          produire dans le cadre de la réalisation des Reportages que leur
          confie SHOOOTIN, y incluant également les trajets aller et retour
          effectués, ainsi que l’exécution des Reportages eux-mêmes. <br />
          <br />
          6.3. Les Utilisateurs devront fournir à SHOOOTIN toute attestation
          d’assurance mentionnée au présent article, et ce à première demande.{' '}
          <br />
        </div>
      </div>
      <div>
        <H5>7. Données personnelles</H5>
        <div css={{ marginBottom: 30 }}>
          7.1. Les Utilisateurs doivent fournir certaines données personnelles
          pour accéder à la Plateforme et créer ainsi un Compte Personnel,
          auquel ils accèdent au moyen d’un identifiant et/ou d’un mot de passe.{' '}
          <br />
          <br />
          Ceci est également valable lorsque des sous-comptes d’un Compte
          Personnel sont créés par les Utilisateurs. Dans ce cas, les personnes
          dont les sous-comptes sont créés doivent être informées de cette
          création ainsi que du traitement de leurs données personnelles par
          l’Utilisateur du Compte Personnel. Les données de ces Utilisateurs de
          sous-comptes étant traitées par SHOOOTIN, le présent article
          s’applique.
          <br />
          <br />
          Les données à caractère personnel des Utilisateurs font l’objet d’un
          traitement automatisé par SHOOOTIN aux fins de gestion et
          d’administration de leur compte, ainsi qu’à des fins de notation de
          l’exécution des Commandes et d’études de statistiques. <br />
          <br />
          Les Utilisateurs reconnaissent et acceptent expressément que leurs
          données à caractère personnel soient transmises par SHOOOTIN à
          d’autres Utilisateurs, ainsi le cas échéant qu’à des tiers mandatés
          par l’Utilisateur qui ne souhaite pas assister à la réalisation du
          Reportage, et ce aux seules fins d’exécution des Reportages dans les
          conditions et modalités prévues aux présentes CGUV. <br />
          <br />
          En revanche, en aucun cas les données personnelles des Utilisateurs ne
          sont transmises à des partenaires de SHOOOTIN. Dans une telle
          éventualité, le consentement des Utilisateurs sera obligatoirement
          sollicité au moyen d’une case à cocher, afin d’autoriser expressément
          une telle transmission. <br />
          <br />
          <br />
          En application de la loi n° 78-17 du 6 janvier 1978 modifiée relative
          à l’informatique, aux fichiers et aux libertés, telle que modifiée par
          la loi n°2004-801 du 6 août 2004 et par le Règlement Européen
          n°2016-679, chaque Utilisateur dispose d’un droit d’accès, de
          modification, de rectification et de suppression des données le
          concernant. Il peut ainsi exiger que soient rectifiées, complétées,
          clarifiées, mises à jour ou effacées les informations le concernant
          qui sont inexactes, incomplètes, équivoques, ou périmées.
          L’Utilisateur peut également, pour des motifs légitimes, s’opposer au
          traitement des données le concernant.
          <br />
          <br />
          Le responsable du traitement des données du Prestataire est : <br />
          <br />
          Nom et prénom : TEPER Jérémy
          <br />
          Téléphone : +33 (0)1.79.72.70.88
          <br />
          Adresse email : contact@shooot.in
          <br />
          <br />
          Chaque Utilisateur peut exercer à tout moment en ligne ce droit en
          accédant à son Compte Personnel. En cas de difficulté, l’Utilisateur
          peut exercer ces droits par email à l’adresse suivante :
          contact@shooot.in ou en écrivant à SHOOOTIN à l’adresse postale
          suivante : SHOOOTIN, 47 Boulevard de Courcelles, 75008 Paris, France.
          <br />
          <br />
          <br />
          7.2. A la clôture du Compte Personnel d’un Utilisateur, pour quelque
          cause que ce soit, les données relatives à ce compte et en particulier
          les données de trafic sont effacées ou rendues anonymes et ne pourront
          être utilisées qu’à des fins statistiques. Cependant, il pourra être
          différé pour une durée maximale d’1 (un) an aux opérations tendant à
          effacer ou à rendre anonymes certaines catégories de données en vue
          d’assurer la sécurité des installations de SHOOOTIN et pour les
          besoins de la recherche, de la constatation et de la poursuite des
          infractions pénales, et dans le seul but de permettre, en tant que de
          besoin, la mise à disposition de l’autorité judiciaire d’informations.
          <br />
          <br />
          7.3 SHOOOTIN est susceptible de mettre en place des cookies sur la
          Plateforme. Il s’agit d’un procédé automatique de traçage qui
          enregistre des informations relatives à la navigation sur la
          Plateforme, et stocke des informations saisies lors des visites afin
          de faciliter la procédure d'inscription et l'utilisation de la
          Plateforme par les Utilisateurs. L’Utilisateur peut s'opposer à leur
          mise en place et/ou les supprimer en suivant la procédure indiquée sur
          son navigateur. <br />
          <br />A ce titre, SHOOOTIN recueille le consentement exprès des
          Utilisateurs quant à l’utilisation de ces cookies, qui sont utilisés
          conformément à la réglementation susvisée, dite loi « Informatique et
          Libertés ». <br />
          <br />
          SHOOOTIN utilise ces cookies uniquement pour établir des statistiques
          de connexion ainsi que des historiques de navigation des Utilisateurs.{' '}
          <br />
          <br />
          7.4. Conformément aux dispositions des présentes CGUV, le Client peut
          mandater un tiers, comme par exemple le locataire du bien ou le
          concierge, pour assister à la réalisation du Reportage du bien
          concerné, s’il ne souhaite pas y assister lui-même. <br />
          <br />
          Dans ce cas, le Client s’engage à demander et obtenir l’autorisation
          expresse de ce tiers pour que ses données à caractère personnel, à
          savoir les nom, prénom et numéro de téléphone, soient collectées et
          transmises par SHOOOTIN conformément aux dispositions légales en
          vigueur, dans les conditions et modalités prévues aux présentes CGUV,
          et ce uniquement pour l’exécution des présentes CGUV. <br />
          <br />
          Les données dudit tiers seront indiquées par le Client et collectées
          par SHOOOTIN au moment où ce dernier publie sa Commande. <br />
          <br />
          Le Client s’engage à fournir tout document présentant l’accord du
          tiers pour la collecte de ses données personnelles par SHOOOTIN, dans
          le cadre de l’exécution des présentes CGUV. <br />
          <br />
          Dès la validation du Reportage par le Client, SHOOOTIN s’engage à
          supprimer dans les meilleurs délais les données personnelles relatives
          au tiers fournies par le Client et collectées par SHOOOTIN. <br />
        </div>
      </div>
      <div>
        <H5>8. Propriété intellectuelle – Droit à l’image</H5>
        <div css={{ marginBottom: 30 }}>
          8.1. Le Prestataire qui réalise le Contenu du Reportage cède à
          SHOOOTIN à titre exclusif, qui accepte, ses droits patrimoniaux sur la
          totalité du Contenu réalisé dans le cadre de chaque Reportage, au fur
          et à mesure de leur création. <br />
          <br />
          Les droits patrimoniaux cédés comprennent notamment les droits de
          reproduction, de représentation, d’adaptation, de traduction, de céder
          à un tiers tout ou partie des droits cédés, et ce par tous modes et
          procédés connus ou inconnus à ce jour. <br />
          <br />
          Ces droits sont cédés pour toute la durée de protection des droits
          d’auteur d’après les lois françaises et étrangères ainsi que les
          conventions internationales actuelles et futures, y incluant toute
          future prorogation éventuelle, et pour le monde entier. <br />
          <br /> 8.2. Après avoir acquis l’intégralité des droits patrimoniaux
          sur le Contenu auprès du Prestataire, SHOOOTIN cède au Client la
          totalité des droits acquis, à titre exclusif. Il est expressément
          convenu que le prix de la cession est inclus dans le prix de la
          prestation de Reportage. <br />
          <br />
          Les droits patrimoniaux cédés comprennent notamment les droits de
          reproduction, de représentation, d’adaptation, de céder à un tiers
          tout ou partie des droits cédés, et ce par tous modes et procédés
          connus ou inconnus à ce jour, pour toute la durée de protection des
          droits d’auteur d’après les lois françaises et étrangères ainsi que
          les conventions internationales actuelles et futures, y incluant toute
          future prorogation éventuelle, et pour le monde entier.
          <br />
          <br />
          SHOOOTIN s’interdit expressément d’autoriser la reproduction, la
          représentation et l’exploitation des œuvres visées aux présentes CGUV
          par d’autres personnes physiques ou morales, à titre commercial ou
          non, pendant la durée de la cession de droits prévue ci-avant, sauf
          accord exprès et préalable du Client.
          <br />
          <br />
          Le Client s’engage à respecter le droit moral du/des Prestataire(s)
          qui a (ont) réalisé le Contenu (mention du nom du Prestataire, non
          modification ou altération du Contenu réalisé). <br />
          <br />
          En cas de non-respect de cette obligation par le Client, la
          responsabilité de SHOOOTIN ne pourra en aucun cas être engagée.
          <br />
          <br />
          Il est entendu que la cession prévue aux présentes ne peut être
          assimilée à une cession globale des œuvres futures, qui est prohibée
          par l’article L. 131-1 du Code de la Propriété Intellectuelle. <br />
          <br />
          Nonobstant la présente cession de droits sur le Contenu par SHOOOTIN
          au Client, SHOOOTIN conserve expressément (i) le droit d’utiliser les
          œuvres cédées à des fins marketing, publicitaires et promotionnelles,
          et ce sur tout support, (ii) la possibilité de conserver les œuvres
          cédées dans une base de données, afin notamment de les fournir à
          nouveau au Client en cas de perte du Contenu cédé, ce que le Client
          reconnaît et accepte expressément. <br />
          <br />
          En tout état de cause, le Prestataire demeurera libre d’utiliser le
          Contenu dans les conditions définies à l’article 8.1 des CGUV. <br />
          <br />
          8.3. Sans que cette liste ne soit exhaustive, la marque « SHOOOTIN »
          ainsi que ses dérivés, les logos, la charte graphique, l’agencement,
          les informations, la présentation et le contenu de la Plateforme, sont
          la propriété exclusive de SHOOOTIN. <br />
          <br />
          Les systèmes, logiciels, structures, infrastructures, bases de données
          et contenus de toute nature (textes, images, visuels, musiques, logos,
          marques, base de données, etc.) exploités par SHOOOTIN sur la
          Plateforme sont également protégés par tous droits de propriété
          intellectuelle ou droits des producteurs de bases de données en
          vigueur. Tout désassemblage, décompilation, décryptage, extraction,
          réutilisation, copie et plus généralement, tout acte de reproduction,
          représentation, diffusion et utilisation de l’un quelconque de ces
          éléments, en tout ou partie, sans l’autorisation de SHOOOTIN est
          strictement interdit et pourra faire l’objet de poursuites
          judiciaires.
          <br />
          <br />
          Toute reproduction ou représentation, totale ou partielle, de la
          Plateforme ou des éléments la composant, tels les marques, les logos,
          la charte graphique, l’agencement, les informations, la présentation
          et le contenu de la Plateforme, sans que cette liste soit limitative,
          sont interdites.
          <br />
          <br />
          L’inscription sur la Plateforme n’emporte aucun transfert de droits de
          propriété intellectuelle au profit de l’Utilisateur.
          <br />
          <br />
          8.4. Le Client déclare à SHOOOTIN et garantit expressément SHOOOTIN
          avoir obtenu toutes les autorisations nécessaires, notamment de la
          part du propriétaire et/ou du locataire du ou des bien(s) concerné(s),
          mais aussi le cas échéant, de l’architecte qui a créé les plans et/ou
          conçu le bien objet du Reportage, ainsi que l’architecte d’intérieur,
          pour que le Prestataire réalise le Reportage dans les conditions
          énoncées aux présentes. Le Client fournira ces autorisations à
          première demande de SHOOOTIN. <br />
          <br />
          En outre, le Client garantit à SHOOOTIN qu’aucune atteinte au droit
          moral de l’architecte ne sera portée du fait du Contenu constituant le
          Reportage. <br />
          <br />
          La responsabilité de SHOOOTIN ne pourra donc en aucun cas être engagée
          à ce titre. <br />
          <br />
          8.5. Lors de la réalisation du Reportage, le locataire, le
          propriétaire, tout tiers mandaté pour assister audit Reportage, et
          plus généralement toute personne physique, ne doit pas figurer sur le
          Contenu. A défaut, l’élément du Contenu concerné ne pourra être livré
          au Client.
          <br />
          <br />
          Toutefois, à la demande du Client, et sur autorisation expresse écrite
          de la personne concernée, le Contenu réalisé dans le cadre des
          présentes peuvent représenter une personne physique (cf. article 3.4).{' '}
          <br />
          <br />
          Le Client s’engage à recueillir auprès la personne concernée
          l’autorisation écrite de reproduire son image conformément aux
          conditions et modalités prévues aux présentes. Le cas échéant, il
          s’engage également à recueillir l’autorisation de la personne
          notamment quant à la mention de ses nom, prénom, qualité et
          profession. <br />
          <br />
          Le Client reconnaît être entièrement responsable de la collecte de ces
          autorisations, la responsabilité de SHOOOTIN ne pourra en aucun cas
          être engagée à ce titre. Le Client s’engage à communiquer, à première
          demande, tout document permettant d’en établir la véracité de ces
          autorisations à SHOOOTIN. <br />
          <br />
          8.6. Nonobstant la résiliation et/ou de manière générale la fin des
          CGUV pour quelque raison que ce soit, les cessions de droits prévues
          aux présentes demeurent valables dans les conditions et modalités
          énoncées.
        </div>
      </div>
      <div>
        <H5>
          9. Informations précontractuelles - Dispositions du Code de la
          consommation applicables à l’Utilisateur non professionnel
        </H5>
        <div css={{ marginBottom: 30 }}>
          Le Client non professionnel reconnaît avoir eu communication,
          préalablement à la Commande, d'une manière claire et compréhensible,
          des présentes Conditions Générales d’Utilisation et de Vente et de
          toutes les informations listées à l'article L.221-5 du Code de la
          consommation et notamment les informations suivantes : <br />
          <br />
          les caractéristiques essentielles des Prestations ; <br />
          le prix des Prestations et des frais annexes ;<br />
          en l'absence d'exécution immédiate du contrat, la date ou le délai
          auquel le Prestataire s'engage à fournir les Prestations commandés ;
          <br />
          les informations relatives à l'identité de SHOOOTIN, à ses coordonnées
          postales, téléphoniques et électroniques, et à ses activités, si elles
          ne ressortent pas du contexte ; <br />
          les informations relatives aux garanties légales et contractuelles et
          à leurs modalités de mise en œuvre ; <br />
          les fonctionnalités du contenu numérique et, le cas échéant, à son
          interopérabilité ; <br />
          la possibilité de recourir à une médiation conventionnelle en cas de
          litige.
          <br />
          <br />
          Conformément aux dispositions du Code de la consommation, les
          Utilisateurs utilisant la Plateforme à titre non professionnel peuvent
          mettre en œuvre les garanties de conformité (articles L211-4 et
          suivants du Code de la consommation) et des vices cachés (article 1641
          et suivants du Code civil). <br />
          <br />
          En revanche, les Utilisateurs non professionnels renoncent
          expressément à leur droit de rétractation en application des
          dispositions de l’article L121-21-8 1° du Code de la consommation.
          <br />
        </div>
      </div>
      <div>
        <H5>10. Inexécution fautive ou manquement grave aux CGUV</H5>
        <div css={{ marginBottom: 30 }}>
          En cas d’inexécution fautive ou de manquement grave à l’une quelconque
          des obligations incombant à l’Utilisateur et/ou au Client, au titre
          des présentes CGUV, le contrat sera résilié automatiquement et de
          plein droit trente jours (30) jours après une mise en demeure
          signifiée à l’Utilisateur et/ou au Client par lettre recommandée avec
          demande d’avis de réception indiquant l’intention de faire jouer la
          présente clause et restée sans effet.
        </div>
      </div>
      <div>
        <H5>10. Non renonciation – Intégralité</H5>
        <div css={{ marginBottom: 30 }}>
          11.1. Le fait pour SHOOOTIN de ne pas se prévaloir d’une défaillance
          ou d’un manquement de l’Utilisateur à l’une quelconque de ses
          obligations, prévues aux présentes ou légales, ne saurait être
          interprété comme une renonciation à se prévaloir de cette défaillance
          ou de ce manquement. Le fait pour SHOOOTIN de ne pas se prévaloir
          d’une stipulation des CGUV n’emporte aucunement renonciation au
          bénéficie de ladite stipulation.
          <br />
          <br />
          11.2. Des accords spécifiques et/ou cadres peuvent intervenir entre
          les Clients et SHOOOTIN. Ces accords et les présentes CGUV forment un
          tout et constituent l’intégralité des conditions et modalités des
          dispositions applicables à leur relation avec SHOOOTIN. <br />
          <br />
          En cas de contradiction entre lesdits accords et les présentes CGUV,
          les accords prévaudront. <br />
        </div>
      </div>
      <div>
        <H5>12. Loi applicable</H5>
        <div css={{ marginBottom: 30 }}>
          Les présentes CGUV seront régies par le droit français.
        </div>
      </div>
      <div>
        <H5>13. Attribution de juridiction</H5>
        <div css={{ marginBottom: 30 }}>
          Tous les litiges entre SHOOOTIN et un Utilisateur, concernant tant la
          validité, l’interprétation, l’exécution, la résolution des présentes
          CGUV leurs conséquences et leurs suites et qui n'auraient pas pu être
          résolus à l'amiable entre SHOOOTIN et le Client, seront soumis à la
          compétence du Tribunal de Grande Instance de Paris si le Client est
          une personne physique ou à la compétence du Tribunal de Commerce de
          Paris si le Client est une personne morale. <br />
          <br />
          L’Utilisateur est informé qu'il peut en tout état de cause recourir à
          une médiation conventionnelle, notamment auprès de la Commission de la
          médiation de la consommation (C. cons. art. L 612-1) ou auprès des
          instances de médiation sectorielles existantes, ou à tout mode
          alternatif de règlement des différends (conciliation, par exemple) en
          cas de contestation.
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
