import React from 'react';
import { H5 } from '../../primitives/appTitle';

export const CguContentGB = () => {
  return (
    <div>
      <div>
        <H5>Acceptance of Terms</H5>
        <div css={{ marginBottom: 30 }}>
          By using the Services, you accept and agree to be bound and abide by
          these Terms, our Privacy Policy found at
          https://shoootin.com/en_us/cgu , and any other term or policy
          incorporated herein by reference. If you do not agree to these Terms,
          the Privacy Policy, or any other incorporated term or policy, you must
          not access or use the Services.
          <br />
          <br />
          The Services are offered and available to users who are 18 years of
          age or older. By using the Services, you represent and warrant that
          you are 18 years of age or older and are otherwise capable of forming
          a legally binding contract with Shoootin. If you do not meet these
          requirements, you must not access or use the Services.
          <br />
          <br />
          You acknowledge and confirm that (i) you have read and understand all
          of the terms, conditions, policies, provisions, disclosures and
          disclaimers contained herein, (ii) these Terms have the same force and
          effect as a signed agreement, and (iii) you expressly accept and agree
          to be bound by the terms hereof.
        </div>
      </div>
      <div>
        <H5>Overview of the Services</H5>
        <div css={{ marginBottom: 30 }}>
          Shoootin is a platform that provides photo, video, 3D, drone, and
          other virtual tour services to customers. As a part of the Services,
          Shoootin utilizes photographers, videographers and other professionals
          to photograph, video, or scan a customer-designated location. Shoootin
          then develops and edits the collected content to create a final
          product delivered to the customer.
        </div>
      </div>
      <div>
        <H5>Changes to these Terms</H5>
        <div css={{ marginBottom: 30 }}>
          We may modify these Terms at any time in our sole discretion. We will
          post notice of changes to these Terms on this page. Changes addressing
          new functions for the Services or changes made for legal reasons will
          be effective immediately. All other changes will become effective when
          (i) you use the Services after you are notified about the change, or
          (ii) thirty days after they are posted (whichever is sooner). We will
          update the Last Modified Date at the top of these Terms accordingly.
          Your continued use of the Services after the Last Modified Date
          signifies your consent to the modified Terms. You are expected to
          check this page frequently so you are aware of any changes, as they
          are binding on you. If the modified Terms are not acceptable to you,
          you should not access or use the Services after the Last Modified
          Date.
        </div>
      </div>
      <div>
        <H5>Availability and Description of Services</H5>
        <div css={{ marginBottom: 30 }}>
          We reserve the right to limit the sales, availability, or quantity of
          the Services to any person, geographic region or jurisdiction. <br />
          <br />
          We attempt to be as accurate as possible but cannot warrant that the
          Services descriptions or other content on the Website is accurate,
          complete, reliable, current, or error-free. All descriptions of the
          Services or pricing are subject to change at any time without notice,
          at our sole discretion. We reserve the right to discontinue any of the
          Services at any time. Any offer for any of the Services made on the
          Website is void where prohibited. <br />
          <br />
          You understand that Shoootin provides the Services over the Internet,
          that such connections may be unpredictable, insecure, and unstable,
          and that Shoootin cannot guarantee uninterrupted access to the
          Services. Shoootin disclaims all liability in connection with any
          security incidents or breaches arising from or attributable to an
          Internet and/or data connection issue. You agree to waive any and all
          claims against Shoootin in connection therewith.
          <br />
          <br />
          We are constantly changing and improving the Services. We reserve the
          right to add or remove functionalities or features of the Services,
          and we may suspend or stop the Services altogether. You may stop using
          the Services at any time. We may also stop providing the Services to
          you, or add or create new limits to the Services or restrict your
          access to all or a part of the Services at any time without notice or
          liability. We will not be liable if for any reason all or any part of
          the Services are unavailable at any time or for any period. The right
          to access and use the Services is revoked in jurisdictions where it
          may be prohibited, if any.
        </div>
      </div>
      <div>
        <H5>User Accounts</H5>
        <div css={{ marginBottom: 30 }}>
          If you choose to create an account with us (“<b>User Account</b>”),
          you will be asked to provide information about yourself, such as name,
          password, email address, physical address, phone number and other
          details. In some instances, you may create sub-accounts for
          individuals or entities other than yourself to access, use, and
          purchase Services through your main account. In other instances, you
          may have access to a sub-account through which you may use and
          purchase Services in connection with a main account created by an
          individual other than yourself. These Terms apply to you in each and
          all of these instances. By creating a sub-account for another
          individual or entity, you affirm that you have notified the individual
          or entity that they are subject to these Terms and you have their
          authority to create the account and submit their information.
          <br />
          <br />
          It is a condition of your use of the Services that all the information
          you provide is correct, current and complete. You agree that all
          information you provide to us is governed by our Privacy Policy, and
          you consent to all actions we take with respect to your information
          consistent with our Privacy Policy. Your registration details and
          other information may not be an impersonation of another person, be a
          term that is the same or confusingly similar to a famous trademark not
          owned by you, or be a term that is offensive in any way.
          <br />
          <br />
          It is your responsibility to update the information associated with
          your User Account if such information has changed. In particular, you
          may receive important correspondences and notifications by email and
          telephone. Shoootin is not responsible for any delay or breach
          resulting from your failure to update the information associated with
          your User Account.
          <br />
          <br />
          You agree to keep your password confidential and to protect your
          account. You agree to notify us immediately of any unauthorized access
          to your account or any other breach of security. You should use
          particular caution when accessing your account from a public or shared
          computer so that others are not able to view or record your personal
          information.
        </div>
      </div>
      <div>
        <H5>Services Requiring Payment of a Fee</H5>
        <div css={{ marginBottom: 30 }}>
          Some of our Services (such as general information and creating an
          account) are provided free of charge, while others are provided for a
          fee. This section applies to all Services that require the payment of
          a fee, which include, but are not limited to, photography,
          videography, 3D, and UAV scanning and production services.
          <br />
          <br />
          <b>Placing an Order</b>
          <br />
          <br />
          To purchase a Service that requires the payment of a fee, you must
          place an order for the Service (“<b>Order</b>”) through your User
          Account. When placing an Order, you will be required to provide us
          information about the location for which you are purchasing the
          Service (“<b>Location</b>”), such as the type of property, address of
          the property, size of the property, and other related information. You
          will also be required to provide information about scheduling a time
          to meet with the photographer, videographer or other professional (“
          <b>Service Provider</b>”) that we have assigned to carry out the
          photo, video, 3D, or UAV shoot (“<b>Shoot</b>”) at the Location. You
          must also provide us with the information necessary for the Service
          Provider to access and meet you at the Location. This includes but is
          not limited to an access code, gate code, or front desk instructions,
          along with any details about the specific place within the Location
          you would like to meet. You also agree to include any special or
          specific instructions about the Shoot in your Order. <br />
          <br />
          You agree and acknowledge that you are responsible for carefully
          reading the instructions and submitting complete and accurate
          information when placing an Order. Shoootin is not responsible for any
          inaccuracies or omissions in information that you provide, nor is
          Shoootin responsible for failures to meet special or specific
          instructions that are not explicitly included in your Order. Our
          provision of the Services is dependent on you providing us all the
          information necessary to carry out the Services, and we may decline to
          provide Services if you do not adequately or timely provide the
          sufficient information. We reserve the right to unilaterally cancel or
          suspend your User Account or Order if information you provide to us is
          inaccurate or misleading. <br />
          <br />
          Prices for the Services are displayed on the Website or provided
          through the process of placing an Order. Prices for the Services are
          subject to change without notice; however, any price change will only
          apply for Services purchased after the change.
          <br />
          <br />
          <b>Photo, Video, or Other Shoot</b>
          <br />
          <br />
          Upon placing a complete Order, you will receive an email confirmation
          setting forth the designated time and location to meet the Service
          Provider for the Shoot. We reserve the right to replace an initially
          assigned Service Provider with another qualified Service Provider
          before or during the provision of the Services. <br />
          <br />
          You must be present to meet the Service Provider at the time and
          location specified and continue to stay on-site at the Location for
          the duration of the Shoot. Notwithstanding the foregoing, you may
          indicate when placing your Order that you designate another individual
          (“<b>Designated Individual</b>”) to meet the Service Provider and be
          present at the Location for the Shoot in your place. In this instance,
          we may require you to provide contact information for the Designated
          Individual. You are responsible for informing and ensuring that the
          Designated Individual understands that they will be bound by these
          Terms for the applicable Services. Your designation and provision of
          information about the Designated Individual, including but not limited
          to the Designated Individual’s telephone and email address, indicates
          that you have the Designated Individual’s consent to provide such
          information to us and to be bound by these Terms, our Privacy Policy,
          and any other incorporated terms. <br />
          <br />
          The Service Provider shall carry out the Shoot as specified in your
          Order. You acknowledge and understand that our Services are primarily
          used to capture the interiors or exteriors of a Location. If you wish
          for the image of an identifiable individual to be captured at a
          Location, you must indicate so when placing your Order and provide the
          required information about such individual. You must also affirm that
          you have the written authorization of such individual to be captured
          through our Services, which shall be provided to us upon our request.
          Except in the specific instance in which an individual has provided
          authorization to have their image captured through our Services, we
          will blur or otherwise alter any identifying portions of any
          individual whose image is captured during a Shoot. <br />
          <br />
          <b>Review and Acceptance of the Report</b>
          <br />
          <br />
          Shoootin will develop and/or edit the photos, videos, images, and
          scans generated from the Shoot (“<b>Content</b>”) to create a report
          for your review (“<b>Report</b>”). In rare instances, we may determine
          that Content or a Report does not meet the criteria that you have
          specified in your Order, in which case we will contact you to
          reschedule a Shoot at no additional cost to you. In this instance of a
          rescheduled Shoot, you acknowledge and agree that our obligations to
          provide timely Content and a Report will be based on the date of the
          rescheduled Shoot. <br />
          <br />
          Following a Shoot, you will be notified by email that a Report is
          available for access and download through your User Account. If you
          are unable to access or download your Report, you must promptly notify
          us by email at contact@shoootin.com. In such case, we will make
          efforts to transmit your Report through other available means. You
          acknowledge and understand that we provide Services over the Internet
          and technology that we are regularly developing and maintaining; we
          are not liable for any security incidents, breaches, or delays arising
          from or attributable to an Internet connection or technology
          malfunction.
          <br />
          <br />
          You will have access to review a Report for seven (7) days following
          the time the Report is initially made available to you. If you
          reasonably determine that a Report does not meet the specifications
          set out in your Order, please submit a complaint (“<b>Complaint</b>”)
          to us by email at contact@shoootin.com. Your Complaint must be based
          on objective factors, such as incorrect number of elements captured in
          the Report, incorrect Designated Individual captured in the Report, or
          other failure to meet objective criteria set forth in your Order.
          Objective criteria shall not include weather conditions present on the
          date of your Shoot. If you have an outstanding Complaint with us or if
          we determine that your Complaint is valid, you do not have the right
          to use or distribute any content from any Report subject to the
          Complaint. You agree that we make the final decision about whether a
          Complaint valid. If we determine that a Complaint is invalid, you must
          accept the Report. <br />
          <br />
          If we determine that a Complaint is valid, we will contact you to
          reschedule a Shoot. If you agree, we may carry out another Shoot and
          produce an updated Report at no additional charge to you.
          Alternatively, you may elect to receive a credit on your User Account
          for the sum paid for the Order in connection with the Report. You may
          use this credit on future Orders; you will not be entitled to any
          refund or reimbursement for money through any other method. <br />
          <br />
          If you take no action in the seven-day timeframe after a Report is
          made available to you, the Report will be deemed accepted.
          <br />
          <br />
          YOU MUST NOT DOWNLOAD A REPORT IF YOU WISH TO SUBMIT A COMPLAINT ABOUT
          THE REPORT. You understand and acknowledge that downloading a Report
          from our Website constitutes your acceptance of the Report and your
          forfeiture of any right to contest the Report. <br />
          <br />
          You understand and agree that our provision of the Services and
          delivery of the Report is dependent on your cooperation and timely
          provision of certain information and materials to us. You agree to
          timely and completely transmit any and all necessary information or
          materials, and be present at the Shoot or designate another individual
          in your place. You acknowledge that your failure to do so may result
          in our delay or failure to perform the Services, and that Shoootin
          shall not be responsible for such delay or failure. <br />
          <br />
          <b>Photography Shooting Services</b>
          <br />
          <br />
          When making an Order, you may select that you are purchasing
          photography services. If you have purchased photography services, your
          Report will be delivered to you within 24 hours of the conclusion of a
          photography Shoot. <br />
          <br />
          <b>Videographic Shooting Services</b>
          <br />
          <br />
          When making an Order, you may select that you are purchasing
          videographic services. This section specifically addresses our terms
          for videographic services. Except if contrary to this section, the
          entirety of these Terms apply to our provision of videographic
          services.
          <br />
          <br />
          If you purchase videographic services, you may elect to integrate
          music into the final video product (“<b>Video</b>”). To enable us to
          integrate music content into the Video, you may provide to us the
          specific music content that you wish to be integrated into the Video.
          In this case, you represent and warrant that you have the right to use
          the entire music content provided to us and, specifically, for the
          purpose of being integrated into the Video. You understand and
          acknowledge that we disclaim any and all liability arising from the
          use of the music content that you provide to us in relation to the
          Video, and you agree to indemnify us for any and all liabilities
          arising from the use and integration of the music content into the
          Video.
          <br />
          <br />
          If you wish to integrate music into the Video, you may also elect to
          utilize music that is part of Shoootin’s music catalog for a specified
          fee. In this instance, you are granted a worldwide, limited,
          revocable, license to utilize the musical piece specifically as it is
          integrated into the Video, and for no other purpose. The musical piece
          may not be sold as a standalone music file or included in any other
          media/stock product, library, or collection for distribution or
          resale; may not be used in, in conjunction with, or in any way that
          may be considered obscene, abusive, immoral, pornographic, derogatory,
          hateful, defamatory, libelous, fraudulent, illegal, or inciteful of an
          illegal act; may not be altered, edited, revised, or presented out of
          the context of the Video into which it is integrated; and may not be
          used in conjunction with sensitive subject matter without the prior
          written consent of Shoootin and/or any other party with ownership or
          usage rights to the musical piece. <br />
          <br />
          If you wish to integrate music into the Video, you may also elect for
          us to engage a third-party music service provider to compose and
          produce music specifically for your request. In this instance, we will
          contract with a third-party music service provider to create and
          produce the music content, and you will be charged on your User
          Account for the amount invoiced to us by the third-party music service
          provider for your request. You may have the option to select whether
          the music content created by the third-party music service provider
          shall be used exclusively for the Video or may be used by you for more
          general purposes. You understand and acknowledge that in all cases in
          which we engage a third-party music service provider upon your
          request, you and your use of the music content created will be subject
          to the terms, conditions, and policies of the third-party music
          provider. You understand that you may be required to enter into a
          licensing agreement with the third-party music provider, in which case
          you agree that it is your responsibility, and not ours, to abide by
          the terms of such licensing agreement. We disclaim any and all
          liability arising from the use of the music content that is created by
          the third-party music service provider and you agree to indemnify us
          for any and all liabilities arising from the use and integration of
          the music content related to the Services. <br />
          <br />
          In most instances, a Report for a videographic services will be
          delivered to you within seventy-two (72) hours of the end of the video
          Shoot. If we are unable to deliver a Report for videographic services
          within this timeframe, we will notify you and work with you to deliver
          the Report.
          <br />
          <br />
          <b>3D Shooting Services (Matterport)</b>
          <br />
          <br />
          When placing an Order, you may select that you are choosing 3D
          shooting services. This section specifically addresses our terms for
          3D shooting services. Except if contrary to this section, the entirety
          of these Terms apply to our provision of 3D shooting services.
          <br />
          <br />
          To provide 3D shooting services, we use Matterport, Inc. (“
          <b>Matterport</b>”) technology, which allows us to create immersive 3D
          tours of indoor and, in certain instances, outdoor locations (“
          <b>3D Models</b>”). To provide 3D shooting Services, we will utilize a
          service provider from Matterport to generate scans of your Location
          using Matterport technology (“<b>Scans</b>”). Matterport owns all
          Scans and 3D Models created, generated, and developed. Matterport
          stores the Models in our Matterport account, and we then provide you
          access to such 3D Models. Matterport is expected to send to us 3D
          Models within 48 hours of the completion of a Shoot, and we will then
          promptly provide you access to such 3D Models. <br />
          <br />
          We will keep the 3D Models in our account for one (1) year, and after
          that time we will delete such models from our account. If you wish for
          us to keep a particular 3D Model for longer than this one-year period,
          you may either (i) create a Matterport account and store the 3D Model
          on your Matterport account, or (ii) pay the fees required for us to
          maintain the 3D Model on our Matterport account. Please inquire with
          us regarding fees applicable to the maintenance of information on our
          Matterport account beyond one year. <br />
          <br />
          Our modelling services through Matterport will be billed on our
          Matterport account, and we will then post a corresponding amount on
          your User Account. We reserve the right to charge an additional fee in
          addition to the fee billed on our Matterport account related to the
          Services. All prices are subject to change.
          <br />
          <br />
          We reserve the right to require and charge for a new 3D order if you
          or a designated third-party individual is not able to be on the
          premises at the Location for the entire duration of the 3D Shoot. We
          also reserve the right to charge applicable fees if there is any
          discrepancy between Location information submitted in your Order and
          the actual Location. Specifically, you may incur additional charges if
          the square footage information submitted in your Order does not
          correspond with the actual square footage of the Location determined
          at the Shoot.
          <br />
          <br />
          We are not responsible for technological deficiencies or malfunctions
          of Matterport technology. In addition to the general limitations of
          liabilities contained in these Terms, Shoootin is not liable for any
          delays, malfunctions, and breaches resulting from the performance or
          nonperformance of Matterport or its technologies. <br />
          <br />
          You must bring any claim or dispute with respect to any Scan or 3D
          Model developed by Matterport or through our Services directly to
          Matterport. You explicitly release Shoootin, its officers, employees,
          agents and successors from claims, demands, and damages of every kind
          or nature, known or unknown, suspected or unsuspected, disclosed or
          undisclosed, arising out of or in any way related to such disputes
          related to Matterport. <br />
          <br />
          <b>UAV (Drone) Shooting Services</b>
          <br />
          <br />
          When placing an Order, you may select that you are choosing unmanned
          aerial vehicle (“<b>Drone</b>”) shooting services. This section
          specifically addresses our terms for Drone services. Except if
          contrary to this section, the entirety of these Terms apply to our
          provision of Drone services.
          <br />
          <br />
          The availability and effectiveness of Drone shooting services are
          subject to factors that may not be in our control or may not be
          readily determinable or foreseeable at the time an Order is placed,
          including but not limited to weather and other natural factors and
          administrative constraints. You understand, acknowledge, and agree
          that we cannot guarantee that Drone shooting services will be
          available or effective on a preselected time and location. If in our
          sole discretion a Drone Shoot may not be carried out at the scheduled
          location or time, we will contact you to reschedule the Drone Shoot,
          if possible, at no additional charge to you. We are not liable for any
          delay or unavailability of Drone shooting services. <br />
          <br />
          You understand and agree that if you affirmatively cancel or modify a
          Drone Shoot, you may incur additional charges.
          <br />
          <br />
          You may be required to enter into a separate services agreement with
          us for the provision of Drone Services. <br />
          <br />
          <b>Retouching Services</b>
          <br />
          <br />
          In some instances, upon your specific request, we may offer retouching
          Services for your Report and related content. Prices for retouching
          Services will vary depending on your specific request, and will be set
          by us. We reserve the right to decline retouching Services in our sole
          discretion.
        </div>
      </div>
      <div>
        <H5>Cancellation or Modification of Order</H5>
        <div css={{ marginBottom: 30 }}>
          You may cancel an Order by following the instructions through your
          User Account on the Website. If a cancellation is submitted at least
          twelve (12) hours prior to the scheduled time to meet your Service
          Provider to begin a Shoot, we will credit to your User Account the
          purchase price of the Order for the Shoot. This credit may be used to
          purchase future Orders for our Services. If a cancellation is received
          less than twelve (12) hours prior to the scheduled time to meet your
          Service Provider to begin a Shoot, you are not entitled to any refund
          or credit of any kind or amount related to the cancelled Order. You
          acknowledge and agree that you are not entitled to receive any refund,
          reimbursement, or credit from Shoootin resulting from the cancellation
          of an Order except what is expressly described in this paragraph. You
          expressly acknowledge and agree that Shoootin is not obligated and
          will not provide to you any refund, reimbursement, or credit of any
          amount for Orders that you cancel within twelve (12) hours of the
          scheduled time to meet the Service Provider to begin the Services,
          regardless of the reason for the cancellation. <br />
          <br />
          You may modify an Order up to three (3) hours prior to the scheduled
          time to meet your Service Provider. Modifications must be made by
          following the instructions through your User Account on the Website.
          Shoootin reserves the right to assess whether a modification requires
          an additional fee to be charged to you. We may require the fee to be
          paid in order for you to receive the Services. We also reserve the
          right to deny your modification of an Order if such modification
          renders the Order impractical or unfeasible to fulfil, or would not
          have been accepted as part of an initial Order. You may not modify an
          order less than three (3) hours prior to the scheduled time to meet
          your Service Provider to begin the Services. If you make any
          modification to your Order less than three (3) hours prior to the
          scheduled time to meet your Service Provider to begin the Services,
          your Order will automatically be cancelled and you will not be
          provided any refund, reimbursement, or credit for the modified Order.
        </div>
      </div>
      <div>
        <H5>Payments</H5>
        <div css={{ marginBottom: 30 }}>
          We collect payments for Services that require a fee payment.
          <br />
          <br />
          When setting up a User Account or placing an Order, you may select
          from several methods of payment, which may include but are not limited
          to:
          <br />
          <br />
          <ul>
            <li>
              One-time payment: You may elect to make a one-time payment by
              credit card at the time you place your Order.
            </li>
            <li>
              Pre-paid User Account: You may elect to pre-pay certain amounts on
              your User Account. When you place an Order, the amount charged for
              the Order will be deducted from the prepaid balance on your User
              Account.
            </li>
            <li>
              Monthly billing: You may elect to be billed at the end of each
              month for purchases and Orders made during the month. For this
              method of payment, you may be required to provide us bank account
              or other billing information from which we are authorized to
              withdraw the monthly billed amount on a recurring monthly basis.
            </li>
            <li>
              Custom billing arrangements: If you would like to be considered
              for an alternative billing arrangement specific to your situation
              and needs, please contact us at contact@shoootin.com.
            </li>
          </ul>
          <br />
          <br />
          Through your User Account, you may also elect for any and all Shoootin
          invoices to be sent to a third-party entity that may pay the invoice
          for your User Account. You affirm that you have the authorization to
          provide information to us for any third-party entity who you add to
          your User Account and that you have notified the third-party entity
          that they are bound by our Terms and all incorporated documents. In
          any case, you understand and agree that you will billed for and are
          responsible for paying the amount of any invoice or outstanding
          balance on your User Account, regardless of whether you have provided
          information for a third-party entity to receive invoices or whether
          that third-party entity has paid any amounts on your User Account at
          any time.
          <br />
          <br />
          In most cases, you may choose after viewing your Report to purchase
          more content than originally indicated while placing an Order. In this
          situation, you will be charged accordingly for the additional content
          purchased. Prices may vary and will be provided to you through our
          provision of the Services and Reports. <br />
          <br />
          All outstanding balances that are not paid within seven days of
          delivery of an invoice by email shall be subject to a recurring late
          fee of 15% monthly and a one-time late penalty fee of $60.
          <br />
          <br />
          All of the Services, including availability and price, are subject to
          change at any time and without prior notification. Availability and
          prices will not change, however, for Services that you have already
          confirmed through an Order. We reserve the right, with or without
          prior notice, to limit the availability of or to discontinue any
          Service, to impose conditions on any promotion, to bar any user from
          making any payment, and to refuse to provide any user with any
          Service.
          <br />
          <br />
          If you wish to purchase any of the Services available through the
          Website (“Purchase”), you may be asked to supply certain information
          relevant to your Purchase including, without limitation, your credit
          card number, the expiration date of your credit card, your billing
          address, and your bank account information to complete payments.
          <br />
          <br />
          You represent and warrant that: (i) you have the legal right to use
          any credit card(s) or other payment method(s) in connection with any
          Purchase; and that (ii) the information you supply to us is true,
          correct and complete.
          <br />
          <br />
          We may employ the use of third-party services for the purpose of
          facilitating payment and the completion of Purchases. By submitting
          your information, you grant us the right to provide the information to
          these third parties subject to our Privacy Policy.
          <br />
          <br />
          All purchases placed on or through the Website or other transactions
          for the sale of services or information through the Website, or
          resulting from visits made by you, are processed through our
          third-party payment service, Stripe. You can read more about Stripe’s
          service agreement and privacy policy by clicking on the appropriate
          link at: www.stripe.com.
          <br />
          <br />
          All Purchases placed on or through the Website are subject to our
          acceptance. Your receipt of an Order confirmation does not signify
          that we have accepted your Purchase. We reserve the right at any time
          after receipt of your Purchase to accept or decline your Purchase for
          any or no reason and without liability to you or anyone else. If your
          method of payment has already been charged for a Purchase that we
          later cancel, we will issue you a refund. Prices are in U.S. dollars
          unless otherwise stated. We may require verification of information
          prior to the acceptance and/or fulfillment of any Purchase.
        </div>
      </div>
      <div>
        <H5>Data Privacy and Personal Information</H5>
        <div css={{ marginBottom: 30 }}>
          By agreeing to these Terms, you also consent to our Privacy Policy
          which is incorporated into these Terms. If you live outside the United
          States, by agreeing to these Terms you consent to the transfer and
          processing of your personal information in the United Kingdom in
          accordance with the Privacy Policy and U.S. law.
          <br />
          <br />
          You should not submit or upload personal information or any other
          information about yourself or others that you are not comfortable
          disclosing to the general public, other than in connection with the
          purchase of the Services in accordance with the Website. This
          information may be viewable by other members of the Shoootin
          community, may be searchable on indexing websites such as Google and
          may be disclosed to third parties under certain circumstances as
          provided in our Privacy Policy.
          <br />
          <br />
          Shoootin disclaims all liability for the disclosure of information
          that you choose to submit voluntarily. You assume all such risks with
          regards to your use of the Services.
          <br />
          <br />
          You understand that your content (other than your payment
          information), may be transferred unencrypted and involve (a)
          transmissions over various networks; and (b) changes to conform and
          adapt to technical requirements of connecting networks or devices.
          Payment information is always encrypted during transfer over networks.
          <br />
          <br />
          You agree that all information you provide to register with or use the
          Services or otherwise is correct, current and complete and is governed
          by our Privacy Policy, and you consent to all actions we take with
          respect to your information consistent with our Privacy Policy. To the
          extent that you provide any information, including but not limited to
          personal information, through the Services, or access or seek access
          to any such information through the Services, you represent, warrant
          and covenant that (i) you are providing or accessing only your own
          information or the information of others that you are authorized to
          provide to third parties, and you have all required consents and
          permissions required to share such information; (ii) the use of such
          information by Shoootin and its representatives and users will not
          infringe upon or misappropriate the intellectual property rights or
          otherwise violate the rights of any third parties, or violate any
          applicable law, rule or regulation; and (iii) you will not provide any
          information or otherwise use the Services in a manner that is harmful,
          fraudulent, threatening, abusive, harassing, tortuous, defamatory,
          vulgar, obscene, libelous, or otherwise objectionable, or that
          jeopardizes the security of your account in any way.
        </div>
      </div>
      <div>
        <H5>Children’s Privacy</H5>
        <div css={{ marginBottom: 30 }}>
          The Services are intended for adults and Shoootin does not knowingly
          collect personal information from children under age 18. If you submit
          content related to your child younger than 18, do not include
          personally identifying information about that child, including but not
          limited to name, address, email address and phone number.
        </div>
      </div>
      <div>
        <H5>International Users</H5>
        <div css={{ marginBottom: 30 }}>
          If you access the Services from a location outside the United Kingdom,
          you are responsible for compliance with the laws of that jurisdiction
          regarding online conduct and acceptable content. Shoootin makes no
          claim that the content of the Services may be utilized, accessed or
          appropriated outside the United Kingdom. You must not access or use
          the Services from a territory that is embargoed by the United Kingdom
          or from which it is illegal to receive products, services, or software
          from the United Kingdom.
        </div>
      </div>
      <div>
        <H5>Third Party Materials</H5>
        <div css={{ marginBottom: 30 }}>
          The Services may link to independent third-party websites or social
          media widgets. Such hyperlinks are provided for your reference and
          convenience only. Shoootin is not liable for the availability or
          accuracy of such third-party sources, and you assume all risk in using
          them. When you link to them, these third-party sites or services may
          place cookies and other tracking technologies on your computer and may
          have different rules for using or sharing any personal information you
          submit to them. We encourage you to read their privacy policies before
          submitting your personal information.
          <br />
          <br />
          When you access third-party websites or interact or communicate with
          third parties, including other users, through the Services, you do so
          at your own risk. Shoootin is not responsible for the actions,
          content, information, data, practices, policies or opinions of any
          third parties, including other users. You are solely responsible for
          your interactions with other users and any other parties with whom you
          interact through the Services. You should make whatever investigation
          you feel necessary or appropriate before proceeding with any online or
          offline interaction with any other person.
          <br />
          <br />
          If you have a dispute with one or more third parties, including other
          users, you release us (and our officers, directors, agents,
          subsidiaries, joint ventures and employees) from claims, demands and
          damages (actual and consequential) of every kind and nature, known and
          unknown, arising out of or in any way connected with such disputes,
          including damages for loss of profits, goodwill, use, privacy or data.
          We reserve the right, but have no obligation, to become involved in
          any way with these disputes. If you are a California resident, you
          waive California Civil Code §1542, which says: “A general release does
          not extend to claims which the creditor does not know or suspect to
          exist in his or her favor at the time of executing the release, which
          if known by him or her must have materially affected his settlement
          with the debtor.” And, if you are not a California resident, you waive
          any applicable state statutes of a similar effect.
          <br />
          <br />
          In addition to the Services offered by Shoootin, the Website may also
          advertise, offer, or make available information, products and/or
          services provided by third parties (collectively, the “
          <b>Third-Party Materials</b>”). Third party products and/or services
          are governed by separate agreements or licenses with the third
          parties. Shoootin offers no guarantees and assumes no responsibility
          or liability of any type with respect to the Third-Party Materials,
          including any liability resulting from incompatibility between third
          party products and/or services and the products and/or services
          provided by Shoootin. You agree that you will not hold Shoootin
          responsible or liable with respect to the Third-Party Materials.
        </div>
      </div>
      <div>
        <H5>User Content</H5>
        <div css={{ marginBottom: 30 }}>
          Shoootin may permit the submission of content or communications
          submitted by users and the hosting, sharing, and/or publishing of such
          content.
          <br />
          <br />
          You grant Shoootin a worldwide, unlimited, irrevocable, royalty-free
          license to publicly and privately use, publish, reproduce, display,
          copy, edit, perform, create derivative works from, or otherwise make
          use of any content you submit on or through the Services (“
          <b>User Content</b>”) in connection with the Services and Shoootin’s
          (and its successors’ or affiliates’) business, including without
          limitation for promoting and redistributing part or all of the
          Services (and derivative works thereof) in any media formats and
          through any media channels. You understand and acknowledge that you
          are responsible for any User Content you submit or contribute, and
          you, not Shoootin, are fully responsible for such content, including
          its legality, reliability, accuracy and appropriateness. We are not
          responsible or liable to any third party for the content or accuracy
          of any User Content posted by you or any other user through the
          Services. We cannot guarantee the identity of any other users with
          whom you may interact in the course of using the Services, or the
          authenticity of any data which users may provide about themselves. You
          acknowledge that all content accessed by you using the Services is at
          your own risk and you will be solely responsible for any damage or
          loss to any party resulting therefrom.
          <br />
          <br />
          Shoootin does not endorse any User Content or any opinion,
          recommendation, or advice expressed therein, and expressly disclaims
          any and all liability in connection with User Content. Shoootin does
          not knowingly permit infringement of intellectual property rights on
          the Services, and will remove User Content if properly notified that
          such User Content infringes on another’s intellectual property rights.
          Shoootin reserves the right to remove User Content without prior
          notice. Shoootin will also terminate a user’s access to the Services
          if a user is determined to be a repeat infringer. Shoootin also
          reserves the right to decide whether User Content is appropriate and
          complies with these Terms for violations other than copyright
          infringement and violations of intellectual property law, such as, but
          not limited to, pornography and obscene or defamatory material.
          Shoootin may remove such User Content and/or terminate a User’s access
          for uploading such material in violation of these Terms at any time,
          without prior notice and at its sole discretion.
          <br />
          <br />
          You understand that when using the Services, you may be exposed to
          User Content from a variety of sources, and that Shoootin is not
          responsible for the accuracy, usefulness, safety, or intellectual
          property rights of or relating to such User Content. You waive, any
          legal or equitable rights or remedies you have or may have against
          Shoootin with respect thereto, and agree to indemnify and hold
          Shoootin, its owners/operators, affiliates, and/or licensors, harmless
          to the fullest extent allowed by law regarding all matters related to
          your use of the Services.
        </div>
      </div>
      <div>
        <H5>User Submissions</H5>
        <div css={{ marginBottom: 30 }}>
          All inquiries, feedback, suggestions, ideas, criticism or other
          information that you provide to Shoootin concerning the Services
          (collectively, “<b>Submissions</b>”) will be treated as
          non-proprietary and non-confidential. By transmitting or posting any
          Submission, you grant Shoootin full ownership of the Submission as if
          Shoootin had created, developed and posted the Submission for its own
          purposes, with no obligation to pay you royalties or any other
          compensation. Please note this is different from User Content, which
          is addressed above. Shoootin reserves the right to copy, use,
          reproduce, modify, adapt, translate, publish, license, distribute,
          sell or assign the Submission in any way as it sees fit, including but
          not limited to copying in whole or in part, creating derivative works
          from, distributing and displaying any Submission in any form, media,
          or technology, whether now known or hereafter developed, alone or as
          part of other works, or using the Submission within or in connection
          with our products or services. You also acknowledge that Submissions
          will not be returned to you and Shoootin may use Submissions, and any
          ideas, concepts or know how contained therein, without payment of
          money or any other form of consideration, for any purpose including,
          without limitation, developing, manufacturing, distributing and
          marketing products and/or services.
          <br />
          <br />
          If you make a Submission, you represent and warrant that you own or
          otherwise control the rights to the Submission. You further represent
          and warrant that such Submission does not constitute or contain
          software viruses, commercial solicitation, chain letters, mass
          mailings, or any form of “spam.” You may not use a false email
          address, impersonate any person or entity, or otherwise mislead
          Shoootin as to the origin of any Submission. You agree to indemnify
          Shoootin for all claims arising from or in connection with any claims
          to any rights in any Submission or any damages arising from any
          Submission.
        </div>
      </div>
      <div>
        <H5>User Conduct</H5>
        <div css={{ marginBottom: 30 }}>
          You may use the Services only for lawful purposes and in accordance
          with these Terms. Without limiting the generality of the foregoing,
          you agree to use the Services in accordance with the provisions below:
          <br />
          <br />
          <ul>
            <li>
              You may not use our products and/or services for any illegal or
              unauthorized purpose nor may you, in the use of the Service,
              violate any laws in your jurisdiction (including but not limited
              to copyright laws).
            </li>
            <li>
              You will be respectful and considerate of other users and do your
              part to make Shoootin a safe, supportive community for all users.
            </li>
            <li>
              You will not use, store, copy, upload, display, post, reproduce,
              modify, translate, republish, distribute, broadcast, transmit,
              create derivative works from, display, license, sell or otherwise
              exploit any part of the Services or content on the Services in any
              form whatsoever other than as expressly permitted under these
              Terms.
            </li>
            <li>
              You will not use contact information received through the Services
              for phishing, spamming and other unsolicited communications or
              data collection.
            </li>
            <li>
              You will not use the Services in a manner intended to damage,
              disable, overburden or impair the Services or to disrupt or
              interfere with another's use of the Services.
            </li>
            <li>
              You will not attempt to hack or otherwise gain unauthorized access
              to the Services or user accounts.
            </li>
            <li>
              You will not affect the display of the Services through use of
              framing, pop-ups or third-party ads.
            </li>
            <li>
              You will not transmit malware, software viruses, Trojan horses,
              worms or any other malicious application to or through the
              Services.
            </li>
            <li>
              You will not threaten, dox, or otherwise harass other users or any
              officer, employee, agent, contractor, or affiliate of Shoootin.
            </li>
            <li>
              You will not post information that is unlawful, inaccurate, false,
              fraudulent, misleading, harassing, libelous, abusive, threatening,
              defamatory, vulgar, obscene, scandalous, inflammatory,
              pornographic, racist, harmful, or otherwise objectionable of any
              kind.
            </li>
            <li>You will not act in a manner that violates these Terms.</li>
            <li>
              You will notify us immediately of any content of the Services that
              violates these Terms.
            </li>
          </ul>
          <br />
          <br />
          We have the right to: (i) remove or refuse to post any User Content
          for any or no reason in our sole discretion; (ii) take any action with
          respect to any User Content that we deem necessary or appropriate in
          our sole discretion, including if we believe that such User Content
          violates these Terms, infringes any intellectual property right or
          other right of any person or entity, threatens the personal safety of
          users of the Services or the public or could create liability for
          Shoootin; (iii) disclose your identity or other information about you
          as required by law or to any third party who claims that material
          posted by you violates their rights, including their intellectual
          property rights or their right to privacy; (iv) take appropriate legal
          action, including without limitation, referral to law enforcement, for
          any illegal or unauthorized use of the Services; and (v) terminate or
          suspend your access to all or part of the Services for any or no
          reason, including without limitation, any violation of these Terms.
          <br />
          <br />
          YOU WAIVE AND HOLD HARMLESS SHOOOTIN AND ITS AFFILIATES, LICENSEES AND
          SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY
          SUCH PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY
          ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY SUCH PARTIES OR
          LAW ENFORCEMENT AUTHORITIES.
          <br />
          <br />
          We have no liability or responsibility to anyone for performance or
          nonperformance of the activities described in this section.
          <br />
          <br />
        </div>
      </div>
      <div>
        <H5>Intellectual Property and Licenses Granted</H5>
        <div css={{ marginBottom: 30 }}>
          The Services and all past, present and future content of the Services,
          including all software, hardware and technology used to provide the
          Services (including our proprietary code and third-party software),
          user interfaces, materials displayed or performed on the Services,
          such as text, graphics, articles, photographs, images, music,
          illustrations and the design, structure, sequence and “look and feel”
          of the Services, and all other intellectual property, including all
          trademarks, service marks, logos, or any other trade name, trademarks,
          service marks and other distinctive or proprietary brand features of
          Shoootin, are (i) owned by Shoootin, its licensors or other providers
          of such material, (ii) protected by United Kingdom and international
          copyright, trademark, patent, trade secret and other intellectual
          property or proprietary rights laws, (iii) are intended solely for the
          lawful use of our users, and (iv) may only be used in accordance with
          these Terms.
          <br />
          <br />
          USING THE SERVICES DOES NOT GIVE YOU OWNERSHIP OF ANY INTELLECTUAL
          PROPERTY RIGHTS IN THE SERVICES OR ANY CONTENT OR REPORT CREATED
          THROUGH THE SERVICES. You may not use content from the Services,
          including User Content, unless you obtain permission from its owner or
          are otherwise permitted by law. You may not remove, obscure, or alter
          any copyright or other legal notices displayed in or along with the
          Services.
          <br />
          <br />
          Upon payment of the applicable fees, Shoootin grants to you an
          exclusive, worldwide, royalty-free, fully paid up, perpetual,
          irrevocable license to use, copy, display and distribute the Reports
          for your marketing and advertising purposes. You may not transfer or
          sublicense the Reports to any third party without our written
          approval.
          <br />
          <br />
          No right, title or interest in or to the Services or any content on
          the Services is transferred to you, and all rights not expressly
          granted are reserved by Shoootin. Any use of the Services not
          expressly permitted by these Terms is a breach of these Terms and may
          violate copyright, trademark and other laws.
          <br />
          <br />
          Except for the express licenses granted herein, nothing in these Terms
          shall be construed to grant you or Shoootin any right or interest in
          or to the other’s intellectual property rights.
          <br />
          <br />
          Shoootin cannot protect or advise you on your intellectual property
          rights, and Shoootin cannot and does not take any responsibility for
          doing so.
        </div>
      </div>
      <div>
        <H5>Claims of Copyright Infringement</H5>
        <div css={{ marginBottom: 30 }}>
          If you believe that your copyrighted work has been unlawfully copied
          and is accessible on the Services in a way that constitutes copyright
          infringement, please send a notice of copyright infringement to:
          <br />
          <br />
          Shoootin LTD
          <br />
          20-22 Wenlock Road
          <br /> N1 7GU London
          <br />
          Email: contact@shoootin.com
          <br />
          <br />
        </div>
      </div>
      <div>
        <H5>Electronic Delivery Statement and Your Consent</H5>
        <div css={{ marginBottom: 30 }}>
          You agree to receive legal notices and other information concerning
          Shoootin or the Services electronically, including notice to any email
          address that you may provide.
        </div>
      </div>
      <div>
        <H5>Disclaimer of Representations and Warranties</H5>
        <div css={{ marginBottom: 30 }}>
          THE SERVICES AND ANY ASSOCIATED CONTENT ARE PROVIDED ON AN “AS IS” AND
          “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
          EXPRESS OR IMPLIED. NEITHER SHOOOTIN NOR ANY PERSON ASSOCIATED WITH
          SHOOOTIN MAKES ANY REPRESENTATION OR WARRANTY WITH RESPECT TO THE
          COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY
          OF THE SERVICES OR ANY ASSOCIATED CONTENT. WITHOUT LIMITING THE
          FOREGOING, NEITHER SHOOOTIN NOR ANYONE ASSOCIATED WITH SHOOOTIN
          REPRESENTS OR WARRANTS THAT THE SERVICES OR ANY ASSOCIATED CONTENT
          WILL BE ACCURATE, RELIABLE, ERROR-FREE, UPDATED OR UNINTERRUPTED, THAT
          DEFECTS WILL BE CORRECTED, THAT THE SERVICES ARE FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS OR THAT THE SERVICES WILL OTHERWISE MEET YOUR
          NEEDS OR EXPECTATIONS.
          <br />
          <br />
          SHOOOTIN HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
          OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
          WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
          PARTICULAR PURPOSE.
          <br />
          <br />
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </div>
      </div>
      <div>
        <H5>Limitation of Liability</H5>
        <div css={{ marginBottom: 30 }}>
          YOUR USE OF THE SERVICES AND ALL PRODUCTS AND SERVICES DELIVERED TO
          YOU THROUGH THE SERVICE AND ANY CONTENT ON THE SERVICES IS AT YOUR
          SOLE RISK AND DISCRETION. You may not assert claims for money damages
          arising from the Services or any associated content against Shoootin,
          its affiliates, licensors or service providers, or our respective
          employees, contractors, officers, directors and agents. Shoootin, its
          affiliates, licensors and service providers, and our respective
          employees, contractors, officers, directors and agents shall not be
          liable for any direct, indirect, special, incidental, consequential or
          punitive damages, including but not limited to personal injury, pain
          and suffering, emotional distress, or loss of revenue, profits,
          goodwill or data, whether caused by tort (including negligence),
          breach of contract or otherwise, even if we knew or should have known
          of the possibility of such damages, arising out of or in connection
          with (i) your use, or inability to use, the Services, any associated
          content, or any products and/or services delivered to you through the
          Service, (ii) any interaction with any third party through or in
          connection with the Services, (iii) any information offered or
          provided within or through the Services, or (iv) any other matter
          relating to the Services. Because some states or jurisdictions do not
          allow the exclusion or the limitation of liability for consequential
          or incidental damages, in such states or jurisdictions, our liability
          shall be limited to the extent permitted by law.
        </div>
      </div>
      <div>
        <H5>Customer Representations</H5>
        <div css={{ marginBottom: 30 }}>
          You represent and warrant that the Reports and Scans will not
          infringe, misappropriate, or otherwise violate the rights of any third
          party, including but not limited to, intellectual property rights of a
          third-party to any aspect of a property or building on Location and
          rights of an owner or tenant to a property or Location. Further, you
          represent and warrant that you have obtained all rights, licenses, and
          permissions for us to access and perform the Services in the Locations
          and to develop, use, copy, translate, adapt, publicly display,
          publicly perform, and otherwise utilize the Reports and the contents
          contained therein.
          <br />
          <br />
          By placing an Order, you represent and warrant that all properties and
          Locations on which our Service Providers or other employees or agents
          are present are insured and that such insurance plan will cover all
          events and damage (including but not limited to property damage and
          bodily injury) that may occur through our provision of the Services.
          You shall provide proof of insurance upon our request.
        </div>
      </div>
      <div>
        <H5>Accuracy, Completeness and Timeliness of Information</H5>
        <div css={{ marginBottom: 30 }}>
          The material on the Website is provided for general information only
          and should not be relied upon or used as the sole basis for making
          decisions without consulting primary, more accurate, more complete or
          more timely sources of information. Any reliance on the material on
          the Website is at your own risk.
          <br />
          <br />
          We reserve the right to modify the contents of the Website at any
          time, but we have no obligation to update any information on the
          Website. You agree that it is your responsibility to monitor changes
          to the Website. <br />
          <br />
          We are not responsible if information made available on the Website is
          not accurate, complete or current.
        </div>
      </div>
      <div>
        <H5>Indemnification</H5>
        <div css={{ marginBottom: 30 }}>
          You are solely legally liable if your use or misuse of the Services,
          any associated content, or any products and/or services delivered to
          you through the Website causes damage to the Services, to you, or to
          someone or something else. You agree to defend, indemnify, and hold
          harmless Shoootin, its affiliates, licensors and service providers,
          and our respective employees, contractors, officers, directors and
          agents from and against all liabilities, claims, damages, judgment,
          awards, losses, costs, expenses, fines or fees (including attorney's
          fees and investigation costs), that arise from or relate to your use
          or misuse of the Services or any content generated or provided as part
          of the Services, or violation of these Terms or violation of a third
          party’s rights. We reserve the right, at our own expense, to assume
          the exclusive defense and control of any matter otherwise subject to
          indemnification by you, in which event you will cooperate with us in
          asserting any available defenses.
        </div>
      </div>
      <div>
        <H5>Term and Termination</H5>
        <div css={{ marginBottom: 30 }}>
          These Terms will remain in effect until terminated by you or Shoootin.
          We reserve the right to terminate or change the Services or your
          access to the Services, without notice, at any time and for any
          reason, including your violation of these Terms or for business
          reasons. We also reserve the right to pursue any additional remedies
          available in law or equity.
          <br />
          <br />
          Shoootin has no obligation to maintain, store, or transfer your
          information or data that you have posted on or uploaded to the
          Services. If your User Account is deactivated, cancelled, or
          terminated, your personal information will be handled in accordance
          with our Privacy Policy.
          <br />
          <br />
          The disclaimer of warranties, limitation of liability, jurisdictional,
          limitation of time to file claims, indemnification and arbitration
          provisions, and your obligation to pay all outstanding amounts on your
          User Account survive any termination.
        </div>
      </div>
      <div>
        <H5>Limitation on Time to File Claims</H5>
        <div css={{ marginBottom: 30 }}>
          Any cause of action or claim you may have arising out of or relating
          to these Terms or the Services must be commenced within one (1) year
          after the cause of action accrues. Otherwise, such cause of action is
          permanently barred.
        </div>
      </div>
      <div>
        <H5>Force Majeure</H5>
        <div css={{ marginBottom: 30 }}>
          Except for our obligation to pay fees on your User Account, neither
          you nor Shoootin will be deemed in breach of these Terms for any
          failure or delay in performance to the extent caused by reasons beyond
          our reasonable control, including, but not limited to, acts of God,
          acts of any governmental body, war, insurrection, sabotage, armed
          conflict, terrorism, embargo, fire, flood, strike or other labor
          disturbance, quarantine or other similar government action, mass
          disease, epidemic or pandemic (e.g. COVID-19), public health
          requirement, unavailability or interruption or delay in
          telecommunications or third-party services, or virus attacks or
          hackers.
        </div>
      </div>
      <div>
        <H5>Choice of Law</H5>
        <div css={{ marginBottom: 30 }}>
          You agree that the laws of the State of New York and U.S. federal law
          govern these Terms, your use of the Services, and any claim or dispute
          that you may have against Shoootin, without regard to New York’s
          conflict of laws rules.
        </div>
      </div>
      <div>
        <H5>Binding Arbitration and Jurisdiction</H5>
        <div css={{ marginBottom: 30 }}>
          In the rare event you have a dispute with Shoootin, we encourage you
          to contact us immediately so we can work together toward a
          satisfactory resolution. <br />
          <br />
          If we cannot come to a resolution, you agree that any claims,
          disputes, demands, counts, controversies, or causes of action between
          you and Shoootin arising out of or relating to these Terms, the
          Services, including the enforceability of these Terms or our Privacy
          Policy or information practices will be finally and exclusively
          resolved by binding arbitration on an individual basis only (except as
          stated below). The arbitrator may not consolidate more than one
          person's claims, and may not otherwise preside over any form of any
          class or representative proceeding, unless Shoootin provides express
          prior written consent.
          <br />
          <br />
          YOU UNDERSTAND THAT YOU ARE WAIVING YOUR RIGHT TO HAVE YOUR CLAIMS
          HEARD IN COURT BY A JUDGE OR JURY. AN ARBITRATION AWARD IS AS
          ENFORCEABLE AS A COURT ORDER AND IS SUBJECT TO ONLY LIMITED REVIEW BY
          A JUDGE. YOU ALSO UNDERSTAND AND AGREE THAT THIS ARBITRATION PROVISION
          PREVENTS YOU FROM PARTICIPATING AS A PLAINTIFF OR AS A CLASS MEMBER IN
          ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.
          <br />
          <br />
          Arbitration will be subject to the Federal Arbitration Act, and shall
          be commenced and conducted by the American Arbitration Association (“
          <b>AAA</b>”) under the Commercial Arbitration Rules and the
          Supplementary Procedures for Consumer Related Disputes, as amended by
          this provision, by one arbitrator that is acceptable to both parties.
          You can find out more about AAA and how to file an arbitration claim
          at www.adr.org or by calling 800-778-7879.
          <br />
          <br />
          Your arbitration fees and your share of arbitrator compensation shall
          be governed by the AAA rules and, where appropriate, limited by the
          AAA Consumer Rules. In the event you are able to demonstrate that the
          costs of arbitration will be prohibitive as compared to the costs of
          litigation, Shoootin will pay as much of your filing, administrative,
          and arbitrator fees in connection with the arbitration as the
          arbitrator deems necessary to prevent arbitration from being
          cost-prohibitive. In such case, we will make arrangements to pay all
          necessary fees directly to the AAA. If the arbitrator determines the
          claim(s) you assert in the arbitration are frivolous, you agree to
          reimburse Shoootin for all fees associated with the arbitration paid
          by Shoootin on your behalf, which you otherwise would be obligated to
          pay under the AAA’s rules.
          <br />
          <br />
          The arbitration will be conducted through written submission unless
          the arbitrator determines that a telephonic or live hearing is
          required. Except as otherwise provided in these Terms, you and
          Shoootin may litigate in court to compel arbitration, stay proceedings
          pending arbitration, or to confirm, modify, vacate or enter judgment
          on the award entered by the arbitrator. In the event this arbitration
          provision is held unenforceable by a court, or in the event AAA
          refuses to arbitrate the dispute, all controversies, disputes,
          demands, counts, claims, or causes of action between you and Shoootin
          shall be exclusively brought in the state or federal courts of New
          York.
        </div>
      </div>
      <div>
        <H5>Severability and Integration</H5>
        <div css={{ marginBottom: 30 }}>
          These Terms and any supplemental terms, policies, rules and guidelines
          posted on the Services constitute the entire agreement between you and
          Shoootin. If any part of these Terms is held invalid or unenforceable,
          that portion shall be construed in a manner consistent with applicable
          law to reflect, as nearly as possible, the original intentions of the
          parties, and the remaining portions shall remain in full force and
          effect. The paragraph headings used herein are for convenience only
          and shall not be used to limit any portion of these Terms.
        </div>
      </div>
      <div>
        <H5>Miscellaneous</H5>
        <div css={{ marginBottom: 30 }}>
          You may not assign these Terms without Shoootin’s prior written
          approval. Shoootin may assign these Terms without your consent to: (i)
          a subsidiary or affiliate; (ii) an acquirer of Shoootin’s business or
          assets; or (iii) a successor by merger. Any purported assignment in
          violation of this section shall be void. <br />
          <br />
          No joint venture, partnership, employment, or agency relationship
          exists between you and Shoootin as a result of these Terms or your use
          of the Services. <br />
          <br />
          Our failure to enforce any right or provision in these Terms shall not
          constitute a waiver of such right or provision unless acknowledged and
          agreed to by Shoootin in writing.
        </div>
      </div>
      <div>
        <H5>Contact Information</H5>
        <div css={{ marginBottom: 30 }}>
          The Services are operated by Shoootin. All feedback, comments,
          requests for technical support and other communications relating to
          the Services should be directed to us at:
          <br />
          <br />
          Shoootin LTD
          <br />
          20-22 Wenlock Road
          <br /> N1 7GU London
          <br />
          contact@shoootin.com
        </div>
      </div>
    </div>
  );
};
