import React from 'react';
import { MainLayout } from 'layout/mainLayout';

import { H2 } from 'primitives/appTitle';
import AppSection from 'primitives/appSection';
import PatternSvg from 'components/svg/patternSvg';
import { defineMessages } from 'react-intl';
import { ShText, useShIntlLocale } from '@shoootin/translations';

import { CguContentFrance } from './cguContentFrance';
import { CguContentSpain } from './cguContentSpain';
import { CguContentUSA } from './cguContentUSA';
import { CguContentGB } from './cguContentGB';

const frontCguPrefix = 'front_cgu';
const FrontCGUMessages = defineMessages({
  title: {
    id: `${frontCguPrefix}_title`,
    defaultMessage: 'Conditions générales',
  },
  subtitle: {
    id: `${frontCguPrefix}_subtitle`,
    defaultMessage: 'CGUV',
  },
});

const FaqPage = () => {
  const locale = useShIntlLocale();

  let content;
  if (locale === 'es_ES') {
    content = <CguContentSpain />;
  } else if (locale === 'en_US') {
    content = <CguContentUSA />;
  } else if (locale === 'en_GB') {
    content = <CguContentGB />;
  } else {
    content = <CguContentFrance />;
  }

  return (
    <MainLayout className="page-faq light" pageName="cgu">
      <AppSection
        className="faq gradient-tt"
        header={
          <span>
            <H2>
              <span>
                <ShText message={FrontCGUMessages.title} />
              </span>
              <PatternSvg />
            </H2>
          </span>
        }
      >
        <p>
          <ShText message={FrontCGUMessages.subtitle} />
        </p>
        <div css={{ marginTop: 50 }}>{content}</div>
      </AppSection>
    </MainLayout>
  );
};

export default FaqPage;
